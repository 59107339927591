/* RESPONSIBLE TEAM: team-ai-chatbot */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type Template from 'embercom/models/template';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import type Store from '@ember-data/store';
import { get, post } from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import { FIN_LANDING_PAGE_PAGEVIEW_KEY } from 'embercom/lib/operator/resolution-bot/constants';
import type IntlService from 'ember-intl/services/intl';
import { Status } from 'embercom/models/data/content-service/ai/ingestion-job-statuses';
import type ContentImportService from 'embercom/services/content-import-service';
import confetti from 'canvas-confetti';
/* @ts-ignore */
import { globalRef } from 'ember-ref-bucket';
import { timeout } from 'ember-concurrency';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import type FinOptInService from 'embercom/services/fin-opt-in-service';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import { markViewAsSeen } from 'embercom/lib/admin-view-tracking';
import moment from 'moment-timezone';

export interface ConversationContentSettings {
  enabled: boolean;
  numSnippets: number;
  numSnippetsAvailable: number;
  immediatelyAvailableToFin: boolean;
  adminIDs: number[];
}

export interface ConversationContentSettingsWireFormat {
  enabled: boolean;
  num_snippets: number;
  num_snippets_available: number;
  immediately_available_to_fin: boolean;
  admin_ids?: number[];
}

const LAST_VIEWED_QUESTION_EXTRACTION_RUN_ID = 'last-viewed-question-extraction-run-id';
const GG_MILESTONE_ONE_LAUNCH_DATE = '2024-07-25';

export default class SetupController extends Controller {
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare store: Store;
  @service declare router: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare permissionsService: any;
  @service declare contentImportService: ContentImportService;
  @service declare onboardingHomeService: any;
  @service declare finOptInService: FinOptInService;

  @tracked declare liveProfilesWithAiAnswersEnabled: boolean;
  @tracked declare liveProfilesWithCustomAnswersEnabled: boolean;

  @tracked declare liveFinProfilesCount: number;
  @tracked declare allArticlesCount: number;
  @tracked declare finUsableArticlesCount: number;
  @tracked declare allCustomAnswersCount: number;
  @tracked declare finUsableCustomAnswersCount: number;
  @tracked declare finUsableExternalContentCount: number;
  @tracked declare allSnippetsCount: number;
  @tracked declare finUsableSnippetsCount: number;
  @tracked declare allActionsCount: number;
  @tracked declare finUsableActionsCount: number;
  @tracked declare allFilesCount: number;
  @tracked declare finUsableFilesCount: number;
  @tracked declare helpCenterSitesCount: number;
  @tracked hardLimitBreached = false;
  @tracked declare conversationContentSettings: ConversationContentSettings;
  @tracked declare hasFinChatWorkflows: boolean;
  @tracked declare hasFinEmailWorkflows: boolean;

  @tracked declare liveFinWorkflowsCount: number;
  @tracked declare liveFinEmailWorkflowsCount: number;
  @tracked declare liveFinChatWorkflowsCount: number;

  @tracked declare finSetupRuleset: $TSFixMe;
  @tracked declare emailSetupRuleset: $TSFixMe;
  @tracked declare templates: Template[];
  @tracked declare activeTab: string;
  @tracked declare previewConfiguration: $TSFixMe;

  @tracked declare latestQuestionExtractionRunId: number;
  @tracked declare shouldShowUpdatedLabel: boolean;
  @tracked declare hasSeenFinLandingPageWithPreview: boolean;

  queryParams = ['openSectionId', 'previewFin', 'activeTab'];
  @tracked declare openSectionId: string;
  @tracked declare previewFin: string;
  @tracked declare confettiActive: boolean;
  @globalRef('completion-screen-confetti-canvas') declare confettiCanvas?: HTMLCanvasElement;
  @tracked showCongratulationBanner = false;

  constructor() {
    super(...arguments);
    this.activeTab = this.activeTab || 'add-content';
  }

  get app() {
    return this.appService.app;
  }

  get isAppCreatedAfterGGM1() {
    return moment(this.appService.app.created_at).isAfter(GG_MILESTONE_ONE_LAUNCH_DATE);
  }

  get showFinPreview(): boolean {
    return this.previewFin === 'true';
  }

  get showFinLandingPage(): boolean {
    if (!this.app.hasConsentedToFinIngestion) {
      return true;
    }
    if (this.app.canUseGreatGuidanceMilestoneOne) {
      return this.showFinPreview && !this.hasSeenFinLandingPageWithPreview;
    }
    return this.isOnboardingStepIncomplete || this.showFinPreview;
  }

  get hasContentReadyForFin(): boolean {
    return (
      this.finUsableArticlesCount > 0 ||
      this.finUsableCustomAnswersCount > 0 ||
      this.finUsableExternalContentCount > 0 ||
      this.finUsableSnippetsCount > 0 ||
      this.finUsableActionsCount > 0 ||
      this.finUsableFilesCount > 0
    );
  }

  get shouldShowAiAnswersHeader(): boolean {
    if (!this.finOptInService.hasOptedInToFin) {
      return false;
    } else if (!this.hasContentReadyForFin) {
      return false;
    } else {
      return (
        this.hasFinEmailWorkflows ||
        this.hasFinChatWorkflows ||
        this.liveProfilesWithAiAnswersEnabled
      );
    }
  }

  get totalContentCount() {
    return (
      this.finUsableArticlesCount +
      this.finUsableCustomAnswersCount +
      this.finUsableExternalContentCount +
      this.finUsableSnippetsCount +
      this.finUsableActionsCount +
      this.finUsableFilesCount
    );
  }

  get onboardingStep() {
    return this.store.peekRecord('onboarding/home/step', 'display_only_try_fin_for_yourself');
  }

  get isOnboardingStepIncomplete() {
    if (!this.onboardingStep) {
      return false;
    }

    return !this.onboardingStep?.completed;
  }

  @action
  switchTab(tab: string) {
    this.activeTab = tab;
    if (tab === 'optimize') {
      if (this.latestQuestionExtractionRunId) {
        localStorage.setItem(
          LAST_VIEWED_QUESTION_EXTRACTION_RUN_ID,
          this.latestQuestionExtractionRunId.toString(),
        );
      }
      this.shouldShowUpdatedLabel = false;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `${tab}-tab`,
    });
  }

  @action
  setOpenSectionId(openSectionId: string) {
    this.openSectionId = openSectionId;
  }

  @action
  async consentToFinIngestion() {
    if (
      this.app.canUseGreatGuidanceMilestoneOne &&
      this.showFinPreview &&
      this.isAppCreatedAfterGGM1
    ) {
      markViewAsSeen(this.store, FIN_LANDING_PAGE_PAGEVIEW_KEY);
      this.router.transitionTo('apps.app.automation.resolution-bot.setup', {
        queryParams: { previewFin: false },
      });
      return;
    }

    if (
      !this.isOnboardingStepIncomplete &&
      this.showFinPreview &&
      this.app.hasConsentedToFinIngestion
    ) {
      this.router.transitionTo('apps.app.automation.resolution-bot.setup', {
        queryParams: { previewFin: false },
      });
      return;
    }

    if (this.isOnboardingStepIncomplete) {
      this.onboardingStep.set('state', 'completed');
      await this.onboardingStep.saveAndRefreshGuide();
    }

    this.previewFin = 'false';

    if (!this.permissionsService.currentAdminCan('can_access_billing_settings')) {
      this.permissionsService.loadAllAdminsAndShowPermissionRequestModal(
        'can_access_billing_settings',
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'blocked',
        object: 'ingestion_consent_page',
      });
      return;
    }
    await this.consentToFinIngestionHelper();
  }

  private async consentToFinIngestionHelper() {
    let params = {
      app_id: this.app.id,
    };
    try {
      await post('/ember/fin_ingestion_consent', params);
      this.app.set('hasConsentedToFinIngestion', true);
      this.intercomEventService.trackAnalyticsEvent({
        action: 'authorized',
        object: 'ingestion_consent_page',
      });
      this.router.transitionTo('apps.app.automation.resolution-bot.setup', {
        queryParams: { previewFin: false },
      });
    } catch (error) {
      this.consentToFinIngestionErrorHandler(error);
    }
  }

  private consentToFinIngestionErrorHandler(error: any) {
    if (error?.jqXHR?.status === 409) {
      // 409 is returned if someone has already consented to Fin ingestion for the workspace
      // This can happen if multiple people load the landing page before consent is given
      // We can safely ignore this error and dismiss the landing page, because we know there is consent from the workspace
      this.app.set('hasConsentedToFinIngestion', true);
      this.router.transitionTo('apps.app.automation.resolution-bot.setup', {
        queryParams: { previewFin: false },
      });
      return;
    }
    this.notificationsService.notifyError(this.intl.t('modal.fin-opt-in.request_error'));
    captureException(new Error('Fin ingestion consent request failed'), {
      fingerprint: ['fin-setup', 'consentToFinIngestion'],
      extra: {
        appId: this.app.id,
      },
      tags: {
        responsibleTeam: 'team-ai-chatbot',
        responsible_team: 'team-ai-chatbot',
      },
    });
  }

  get availableCustomBotConfigs() {
    return Object.values(CUSTOM_BOT_CONFIG).filter((config) => config.visibleToApp(this.app));
  }

  @action
  async reloadModel() {
    this.send('refreshModel');
  }

  get showBanner(): boolean {
    return this.contentImportService.finIngestionJobStatusBanner?.display ?? false;
  }

  get workspaceLevelIngestionInProgress(): boolean {
    return (
      this.showBanner &&
      (this.contentImportService.finIngestionJobStatusBanner?.status === Status.InProgress ||
        this.contentImportService.finIngestionJobStatusBanner?.status === Status.Pending)
    );
  }

  get workspaceLevelIngestionCompleted(): boolean {
    return (
      this.showBanner &&
      this.contentImportService.finIngestionJobStatusBanner?.status === Status.Completed
    );
  }

  get canUseEssentialsSetupExperience(): boolean {
    return !this.app.canUseFeature('workflows_core_billing_feature');
  }

  get usageLimitArticleLink() {
    if (this.appService.app.usesResolutionsWithCustomAnswers) {
      return 'https://www.intercom.com/help/en/articles/8443037-how-to-manage-your-fin-usage';
    } else {
      return 'https://www.intercom.com/help/en/articles/8060969-get-notified-of-your-fin-usage';
    }
  }

  get finSetupRulesetStateIsLive() {
    return (
      this.finSetupRuleset?.state === states.live || this.emailSetupRuleset?.state === states.live
    );
  }

  get finSetupChatRulesetStateIsLive() {
    return this.finSetupRuleset?.state === states.live;
  }

  get finSetupEmailRulesetStateIsLive() {
    return this.emailSetupRuleset?.state === states.live;
  }

  get totalFinLiveChatCount() {
    return this.liveFinChatWorkflowsCount + this.liveFinProfilesCount;
  }

  get lastUpdatedTime() {
    let timestampInMilliseconds = this.latestQuestionExtractionRunId * 1000;
    return new Date(timestampInMilliseconds);
  }

  @action
  async showConfetti() {
    if (this.app.canUseGreatGuidanceMilestoneOne) {
      // Don't show confetti if the user is on great guidance experience
      return;
    }
    this.confettiActive = true;
    await timeout(100);
    let confettiObj = confetti.create(this.confettiCanvas, {
      resize: true,
    });

    for (let i = 4; i < 7; i++) {
      confettiObj({
        particleCount: 100,
        spread: 200,
        ticks: 2000,
        gravity: 2,
        origin: { x: i / 10, y: 0.3 },
      });
    }

    await timeout(2000);
    this.confettiActive = false;
  }

  @action
  async reloadFinWorkflows() {
    await this.reloadFinWorkflowsCount();
  }

  @action
  async reloadSetupRuleset(setupType: ChannelType) {
    if (setupType === 'email') {
      let rulesetId = this.emailSetupRuleset.id;
      this.emailSetupRuleset = this.store.peekRecord('matching-system/ruleset', rulesetId);
      await this.emailSetupRuleset.reload();
    } else {
      let rulesetId = this.finSetupRuleset.id;
      this.finSetupRuleset = this.store.peekRecord('matching-system/ruleset', rulesetId);
      await this.finSetupRuleset.reload();
    }
  }

  private async reloadFinWorkflowsCount() {
    let response = await get('/ember/operator_workflows/get_live_fin_workflows_count', {
      app_id: this.app.id,
    });

    this.liveFinChatWorkflowsCount = response.live_chat_count;
    this.liveFinEmailWorkflowsCount = response.live_email_count;
    this.liveFinWorkflowsCount = +response.live_chat_count + +response.live_email_count;
    this.hasFinChatWorkflows = response.has_chat_workflows;
    this.hasFinEmailWorkflows = response.has_email_workflows;
  }
}
