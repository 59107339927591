/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ExportColumns from 'embercom/lib/reporting/export-columns';
import TicketExportColumns from 'embercom/lib/reporting/ticket-export-columns';
import {
  FilterSet,
  TicketFilterSet,
  getFilterPredicatesFromFilterSet,
} from 'embercom/lib/reporting/flexible/filter-helpers';
import WeekDays from 'embercom/lib/reporting/week-days';
import { getOwner } from '@ember/application';

export default class ExportController extends Controller {
  @service store;
  weekDays = new WeekDays(getOwner(this)).values;

  @service reportingService;
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service permissionsService;
  @service intl;
  @service modalService;

  @tracked enqueuingExport = false;
  @tracked schedules;
  @tracked scheduleToEdit;
  @tracked selectedColumns = this.exportColumnsData.getColumnNames(this.app);
  @tracked filterSet = this.newFilterSet();
  @tracked initialFiltersAddedDirty;
  @tracked selectedTab = 'conversations';

  get app() {
    return this.appService.app;
  }

  get adminId() {
    return this.app.currentAdmin.id;
  }

  get adminEmail() {
    return this.app.currentAdmin.email;
  }

  get editMode() {
    return Boolean(this.scheduleToEdit?.id);
  }

  get scheduleIds() {
    return this.schedules.map((sch) => sch.id).filter((schId) => Boolean(schId));
  }

  get rangeSetting() {
    return this.reportingService.range.selectedRange;
  }

  get isScheduleDisabled() {
    return this.rangeSetting === 'custom';
  }

  get successMessage() {
    if (this.editMode) {
      return this.intl.t('apps.app.reports.customer-support.export.notifications.schedule-edit');
    } else {
      let dayInfo = this.intl.t(
        'apps.app.reports.customer-support.export.notifications.create-schedule.day-info.daily',
      );
      if (this.scheduleToEdit.dayOfMonth) {
        dayInfo = this.intl.t(
          'apps.app.reports.customer-support.export.notifications.create-schedule.day-info.monthly-schedule',
          {
            dayOfMonth: this.scheduleToEdit.dayOfMonth,
          },
        );
      } else if (
        this.scheduleToEdit.dayOfWeek !== null &&
        this.scheduleToEdit.dayOfWeek !== undefined
      ) {
        dayInfo = this.intl.t(
          'apps.app.reports.customer-support.export.notifications.create-schedule.day-info.weekly-schedule',
          {
            dayOfWeek: this.weekDays[this.scheduleToEdit.dayOfWeek],
          },
        );
      }
      return this.intl.t(
        'apps.app.reports.customer-support.export.notifications.create-schedule.success-message',
        {
          dayInfo,
        },
      );
    }
  }

  get isScheduleManagementDisabled() {
    // Length equal to 1 means the only schedule is the one which is being edited
    return this.schedules.length === 1;
  }

  get filterPredicates() {
    return getFilterPredicatesFromFilterSet(this.filterSet);
  }

  get customAttributeIds() {
    return this.conversationAttributeDescriptors.map((descriptor) => descriptor.id);
  }

  get exportColumnsData() {
    return this.selectedTab === 'conversations'
      ? new ExportColumns(getOwner(this))
      : new TicketExportColumns(getOwner(this));
  }

  get allColumns() {
    return this.exportColumnsData.getColumnData(this.app);
  }

  get allColumnNames() {
    return this.exportColumnsData.getColumnNames(this.app);
  }

  get attributes() {
    return this.selectedTab === 'conversations' ? this.conversationAttributeDescriptors : [];
  }

  get analyticsPayload() {
    return {
      action: 'started_message_data_export',
      object: 'message_data_export',
      message_ids: null,
      export_flag: this.exportFlag,
    };
  }

  newFilterSet() {
    return this.selectedTab === 'conversations' ? new FilterSet() : new TicketFilterSet();
  }

  @action
  clearFilterSet() {
    this.filterSet = this.newFilterSet();
    this.initialFiltersAddedDirty = true;
  }

  @action
  filterBarSelectionChanged(filterType, filterValues, operator) {
    if (this.customAttributeIds.includes(filterType)) {
      this.filterSet.customAttributes.updateValues(filterType, filterValues, operator);
      this.filterSet.customAttributes = this.filterSet.customAttributes.clone();
    } else {
      this.filterSet[filterType] = filterValues.flat();
    }
  }

  _recordFilteredByDateAnalyticsEvent() {
    let range = this.reportingService.range;
    let params = {
      action: 'filtered_by_date',
      object: this.reportName,
      date_filter: this.range.selectedRange,
      start_date: range.startMoment.format('YYYY/MM/DD'),
      end_date: range.endMoment.format('YYYY/MM/DD'),
    };
    this.intercomEventService.trackAnalyticsEvent(params);
  }

  @action
  updateRange(range) {
    this.reportingService.updateRange(range, null, false);
    this._recordFilteredByDateAnalyticsEvent();
  }

  _enqueueExportQuery(params) {
    let { start, end, selectedColumns, filter, appId } = params;
    return ajax({
      url: '/ember/message_data_export/enqueue',
      type: 'POST',
      data: JSON.stringify({
        app_id: appId,
        range_start: start,
        range_end: end,
        columns_to_include: this.selectedColumnsWithTicketTypeIds(selectedColumns),
        filter,
        flag: this.exportFlag,
      }),
    });
  }

  selectedColumnsWithTicketTypeIds(columns) {
    if (this.filterSet.ticket_type_ids && this.filterSet.ticket_type_ids.length > 0) {
      return columns.concat([`ticket_type_ids: ${this.filterSet.ticket_type_ids.join(',')}`]);
    }
    return columns;
  }

  get exportFlag() {
    return this.selectedTab === 'conversations' ? 'respond' : 'tickets';
  }

  @action
  enqueueExport() {
    if (this.permissionsService.currentAdminCan('can_export_csv')) {
      this.enqueuingExport = true;

      let { start, end } = this.range;

      return this._enqueueExportQuery({
        start,
        end,
        selectedColumns: this.selectedColumns,
        filter: this.filterPredicates,
        appId: this.get('app.id'),
      })
        .then(() => {
          this.notificationsService.notifyConfirmation(
            this.intl.t(
              'apps.app.reports.customer-support.export.notifications.enqueue-export.confirmation',
              {
                adminEmail: this.adminEmail,
              },
            ),
          );
          let range_days = this.get('range.inDays');
          this.intercomEventService.trackAnalyticsEvent({
            ...this.analyticsPayload,
            range_days,
            exported_fields: this.selectedColumns.join(','),
          });
          this.modalService.closeModal();
          this.enqueuingExport = false;
        })
        .catch((err) => {
          this.notificationsService.notifyResponseError(err, {
            default: this.intl.t(
              'apps.app.reports.customer-support.export.notifications.enqueue-export.error',
            ),
          });
          this.enqueuingExport = false;
        });
    } else {
      return this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_export_csv');
    }
  }

  @action
  resetSchedule() {
    let isNew = this.scheduleToEdit.isNew;
    this.scheduleToEdit.rollbackAttributes();
    if (isNew) {
      this.scheduleToEdit = this.store.createRecord('reporting/message-data-export-schedule', {
        timeToExport: 0,
      });
    }
  }

  @action
  loadSchedule(scheduleId) {
    if (!scheduleId) {
      this.selectedColumns = this.exportColumnsData.getColumnNames(this.app);
      this.clearFilterSet();
      this.reportingService.range.setDefaultRange('past_week');
    }
    this.transitionToRoute({ queryParams: { scheduleId } });
  }

  @action
  onSelectionChange(value) {
    this.set('selectedTab', value);
    this.selectedColumns = this.exportColumnsData.getColumnNames(this.app);
    this.filterSet = this.newFilterSet();
  }

  _assignMissingAttributes() {
    this.scheduleToEdit.adminId = this.adminId;
    this.scheduleToEdit.columnsToInclude = this.selectedColumns;
    this.scheduleToEdit.rangeSetting = this.rangeSetting;
    this.scheduleToEdit.filterPredicates = JSON.stringify(this.filterPredicates);
  }

  @task *sendScheduleExportQuery() {
    this._assignMissingAttributes();
    this.intercomEventService.trackAnalyticsEvent({
      object: 'message_data_export',
      action: 'schedule_created',
      exported_fields: this.scheduleToEdit.columnsToInclude.join(','),
    });
    let isNew = this.scheduleToEdit.isNew;
    try {
      let successMessage = this.successMessage;
      yield this.scheduleToEdit.save();
      this.notificationsService.notifyConfirmation(successMessage);
    } catch {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.reports.customer-support.export.notifications.create-schedule.error'),
      );
    }
    if (isNew) {
      this.scheduleToEdit = this.store.createRecord('reporting/message-data-export-schedule', {
        timeToExport: 0,
      });
    }
  }

  @action
  deleteSchedule() {
    this.sendDeleteQuery.perform();
  }

  @task *sendDeleteQuery() {
    try {
      yield this.scheduleToEdit.destroyRecord();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'apps.app.reports.customer-support.export.notifications.delete-schedule.confirmation',
        ),
      );
    } catch {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.reports.customer-support.export.notifications.delete-schedule.error'),
      );
    }
    this.loadSchedule(null);
  }

  @action
  openSidebar() {
    // The default selected schedule should be the last one that was created
    let schedulesWithIds = this.schedules.filter((sch) => sch?.id);
    if (schedulesWithIds.length) {
      this.loadSchedule(schedulesWithIds[schedulesWithIds.length - 1].id);
    }
  }

  @action
  closeSidebar() {
    this.loadSchedule(null);
  }
}
