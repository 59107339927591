/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import { filterBy, gt } from '@ember/object/computed';
import { findBy } from '@intercom/pulse/lib/computed-properties';
import Controller from '@ember/controller';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency';

export default Controller.extend({
  store: service(),
  schedules: filterBy('model.schedules', 'isNew', false),
  settings: filterBy('model', 'settings'),
  scheduleToUpdate: null,
  showModal: false,
  scheduleToDelete: null,
  defaultSchedule: findBy('schedules', 'isDefault', true),
  customSchedules: filterBy('schedules', 'isDefault', false),
  hasCustomSchedules: gt('customSchedules.length', 0),

  dependentObjectsForSchedule(scheduleToDelete) {
    return ajax({
      url: '/ember/office_hours_schedules/dependent_objects',
      type: 'GET',
      data: {
        app_id: this.get('app.id'),
        id: scheduleToDelete.id,
      },
    });
  },

  attemptDeleteSchedule: task(function* (scheduleData) {
    let scheduleToDelete = this.model.schedules.findBy('name', scheduleData.name);
    let objects = yield this.dependentObjectsForSchedule(scheduleToDelete);
    this.set('scheduleToDelete', scheduleData);
    this.set('dependentObjects', objects);
    if (objects.length > 0) {
      this.set('showConfirmScheduleDelete', true);
    } else {
      this.deleteSchedule.perform();
    }
  }),

  deleteSchedule: task(function* () {
    let scheduleToDelete = this.model.schedules.findBy('name', this.scheduleToDelete.name);
    yield scheduleToDelete.destroyRecord();
    this.set('scheduleToDelete', null);
  }),

  actions: {
    createOfficeHours() {
      this.set('scheduleToUpdate', this.store.createRecord('office-hours-schedule'));
      this.set('showModal', true);
    },
    openSettingsModal(scheduleData) {
      this.set('scheduleToUpdate', this.model.schedules.findBy('name', scheduleData.name));
      this.set('showModal', true);
    },
    closeModal() {
      this.set('showModal', false);
      this.set('scheduleToDelete', null);
      this.set('showConfirmScheduleDelete', null);
      if (this.scheduleToUpdate) {
        this.scheduleToUpdate.rollbackAttributes();
        this.set('scheduleToUpdate', null);
      }
    },
  },
});
