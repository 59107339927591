/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { readOnly, notEmpty } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { isValidEmail } from 'embercom/lib/email';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { action } from '@ember/object';
import { isValidEmail as isValidEmailAddress } from '@intercom/pulse/lib/computed-properties';
import storage from 'embercom/vendor/intercom/storage';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import ENV from 'embercom/config/environment';

const MANUALLY_ADDED = 0;
const LOCALSTORAGE_EMAIL_KEY = 'forward-emails-component-email';

export default Controller.extend({
  notificationsService: service(),
  intl: service(),
  appController: controller('apps/app'),
  app: readOnly('appController.model'),
  addCompanyEmailAddressFormExpanded: false,
  companyEmailAddresses: readOnly('app.companyEmailAddresses'),
  hasCompanyEmailAddresses: notEmpty('companyEmailAddresses'),
  store: service(),
  realTimeEventService: service(),
  intercomEventService: service(),
  guideLibraryService: service(),
  email: '',
  emailSet: false,
  verification: null,
  maxRetryAttempts: 20,
  retryAttempts: 0,
  isValidEmail: isValidEmailAddress('email'),
  hasForwardingEmail: notEmpty('email'),
  inboxAddress: readOnly('app.inbox_email'),

  duplicateEmailAddress(emailAddress) {
    let companyEmailAddresses = this.companyEmailAddresses;
    let duplicateFound = false;
    companyEmailAddresses.forEach(function (item) {
      if (item.get('email_address') === emailAddress) {
        duplicateFound = true;
      }
    });
    return duplicateFound;
  },
  actions: {
    expandAddCompanyEmailAddressForm() {
      this.set('addCompanyEmailAddressFormExpanded', true);
    },
    collapseAddCompanyEmailAddressForm() {
      this.set('addCompanyEmailAddressFormExpanded', false);
    },
    addNewCompanyEmailAddress(emailAddress) {
      if (!isValidEmail(emailAddress)) {
        this.notificationsService.notifyWarning(
          this.intl.t('apps.app.settings.email-forwarding.enter-valid-email'),
        );
      } else if (this.duplicateEmailAddress(emailAddress)) {
        this.notificationsService.notifyWarning(
          this.intl.t('apps.app.settings.email-forwarding.email-already-added'),
        );
      } else {
        let companyEmailAddress = this.store.createRecord('company-email-address', {
          email_address: emailAddress,
          source: MANUALLY_ADDED,
        });
        this.send('collapseAddCompanyEmailAddressForm');
        companyEmailAddress
          .save()
          .then(() => {
            this.set('newCompanyEmailAddress', '');
            this.notificationsService.notifyConfirmation(
              this.intl.t('apps.app.settings.email-forwarding.registered'),
            );
          })
          .catch(() => {
            this.notificationsService.notifyError(
              this.intl.t('apps.app.settings.email-forwarding.email-not-saved'),
            );
            this.send('expandAddCompanyEmailAddressForm');
            this.store.deleteRecord(companyEmailAddress);
          });
      }
    },
  },

  didInsertElement() {
    this._super(...arguments);
    this.restoreEmailFromStorage();
    this.realTimeEventService.on('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
  },

  willDestroyElement() {
    this.realTimeEventService.off('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
    this._super(...arguments);
  },

  restoreEmailFromStorage() {
    if (this.email !== '') {
      return;
    }
    let email = storage.get(LOCALSTORAGE_EMAIL_KEY);
    if (!email) {
      return;
    }
    this.set('email', email);
    this.set('emailSet', true);
  },

  get analyticsMetadata() {
    return {
      place: 'settings',
      owner: 'team-activation',
      object: 'email_forwarding_steps',
    };
  },

  verifyEmailForwarding: task(function* () {
    this._trackAnalyticsEvent({ action: 'clicked', object: 'send_test_email' });
    let verification;
    yield this._createSetupAttempt();
    yield this._sendForwardingEmail();

    this.set('retryAttempts', 0);
    verification = yield this._verifyEmailForwarding();
    try {
      while (!verification && this.retryAttempts < this.maxRetryAttempts) {
        yield timeout(ENV.APP._1000MS); // wait for nexus event
        this.incrementProperty('retryAttempts');
        verification = yield this._verifyEmailForwarding();
      }
    } catch (e) {
      captureException(e, {
        fingerprint: ['forward-emails', 'verify_email_forwarding'],
      });
      this.set('retryAttempts', 0);
    }

    if (!this.verification) {
      this._updateVerification(verification);
      this._trackAnalyticsEvent({
        action: verification ? 'completed' : 'failed',
        context: 'verify_endpoint',
        object: 'send_test_email',
      });
    }
  }),
  async _createSetupAttempt() {
    let forwardMailSetupAttempt = await this.store
      .createRecord('forward-mail-setup-attempt', {
        appId: this.get('app.id'),
        forwardingEmailAddress: this.email,
      })
      .save();

    this.set('forwardMailSetupAttempt', forwardMailSetupAttempt);
  },
  async _sendForwardingEmail() {
    await ajax({
      url: '/ember/forward_emails',
      data: JSON.stringify({
        app_id: this.get('app.id'),
        email: this.email,
      }),
      type: 'POST',
      contentType: 'application/json',
      dataType: 'JSON',
    });
  },
  async _verifyEmailForwarding() {
    return await ajax({
      url: '/ember/forward_mail_setup_attempts/verify',
      type: 'GET',
      data: {
        app_id: this.get('app.id'),
        forwarding_email_address: this.email,
      },
    });
  },
  _handleForwardMailVerifiedMessage() {
    this._updateVerification(true);
    if (this.guideLibraryService.canUseGuideLibraryService) {
      this.guideLibraryService.markStepCompleted(
        'guide_library_foundational_steps_setup_omnichannel_v2',
      );
    }
    this._trackAnalyticsEvent({
      action: 'completed',
      object: 'send_test_email',
      context: 'nexus_event',
    });
  },
  _updateVerification(status) {
    this.set('verification', status);
    if (this.verification) {
      this.completionCallback?.();
    }
  },
  _trackAnalyticsEvent(options) {
    this.intercomEventService.trackAnalyticsEvent({
      email: this.email,
      ...options,
      ...this.analyticsMetadata,
    });
  },
  setEmail: action(function () {
    if (this.isValidEmail) {
      storage.set(LOCALSTORAGE_EMAIL_KEY, this.email);
      this.set('emailSet', true);
      this._trackAnalyticsEvent({
        action: 'clicked',
        object: 'set_email',
      });
    }
  }),
  unsetEmail: action(function () {
    storage.remove(LOCALSTORAGE_EMAIL_KEY);
    this.set('verification', null);
    this.set('emailSet', false);
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'unset_email',
    });
  }),
});
