/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import type Store from '@ember-data/store';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type Price from 'embercom/models/price';

export default class MigrationController extends Controller {
  queryParams = ['tab'];

  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare store: Store;

  @tracked declare tab: string;
  // @ts-ignore
  @tracked pricing5Estimates = this.model.pricing5Estimates;

  tabContainerClass = 'stripe-migrations-header';

  @action
  changeTab(tab: string) {
    this.tab = tab;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'stripe_migrations',
      place: tab,
      object: 'tab_changes',
    });

    document.querySelector(`.${this.tabContainerClass}`)?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  @action
  async onMigrationSeatConfigurationSave() {
    let newEstimates = await this.fetchNewEstimatesWithUpdatedSeatCounts();

    this.pricing5Estimates = newEstimates;
  }

  private async fetchNewEstimatesWithUpdatedSeatCounts(): Promise<Array<Price>> {
    let estimates = await this.store.query('migration-estimate', {
      app_id: this.appService.app.id,
    });

    return estimates.toArray();
  }
}
