/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
import { filterBy, readOnly } from '@ember/object/computed';
import AttributeSettingsController from 'embercom/controllers/apps/app/settings/base/attribute-settings-controller';
import { inject as service } from '@ember/service';

let CompanyDataSettings = AttributeSettingsController.extend({
  attributeService: service(),
  attributes: readOnly('attributeService.displayableCompanyAttributes'),
  archivedAttributes: filterBy('archivedAttributesWithSettings', 'isCompany', true),

  companyAttributes: readOnly('attributes'),
  companyStandardAttributes: filterBy('companyAttributes', 'isCustomData', false),
  companyCustomAttributes: filterBy('companyAttributes', 'isCustomData', true),
  archivedCompanyAttributes: readOnly('archivedAttributes'),
  checkboxesLocked: false,

  // For triggering the focus of corresponding attribute description table cell
  queryParams: ['attributeForDescriptionEditing'],
  attributeForDescriptionEditing: null,

  actions: {
    lockCheckboxes() {
      this.set('checkboxesLocked', true);
    },
    unlockCheckboxes() {
      this.set('checkboxesLocked', true);
    },
  },
});

export default CompanyDataSettings;
