/* RESPONSIBLE TEAM: team-product-exploration */

import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class GeneralController extends Controller {
  queryParams = ['highlight'];
  declare model: { generalSettings: any; messengerSettings: any; companyAttribute: any };

  @tracked highlight = null;
}
