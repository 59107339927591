/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import { filterBy } from '@ember/object/computed';
import SegmentOrTagController from 'embercom/controllers/apps/app/settings/base/segment-tag-controller';

export default SegmentOrTagController.extend({
  nonDeletedTagsOrSegments: filterBy('app.userSegments', 'isDeleted', false),
});
