/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';

export default Controller.extend({
  intercomEventService: service(),
  appController: controller('apps/app'),
  app: reads('appController.model'),
  store: service(),

  notificationsService: service('notificationsService'),

  showAddCustomSenderModal: false,

  customBounceSettings: reads('app.customBounceSettings'),

  dkimSettings: reads('app.dkim_settings'),

  customEmailAddresses: reads('app.customEmailAddresses'),

  actions: {
    onOpenSectionChange(newOpenSectionId) {
      this.set('openSectionId', newOpenSectionId);
    },

    toggleDisplayCustomSenderModal() {
      this.toggleProperty('showAddCustomSenderModal');
      if (this.showAddCustomSenderModal) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'clicked',
          object: 'create_custom_sender_address',
          context: 'from_settings_page',
        });
      }
    },
  },
});
