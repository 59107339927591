/* RESPONSIBLE TEAM: team-tickets-1 */
// Nolaneo – This controller had a mixture of octane and classic ember patterns
// which was causing issues with the new ESLint parser. I've split the octane bit out here.
// August 2022.

import { tracked } from '@glimmer/tracking';
import OldTeamsController from './old-teams';
import { inject as service } from '@ember/service';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import { task } from 'ember-concurrency-decorators';

export default class TeamsController extends OldTeamsController {
  @tracked tab = 'teams';
  @tracked createAdminTeamDrawerOpen = false;
  @tracked updatingAdminTeam = {};
  @tracked liveWorkflows = null;

  @service intercomEventService;
  @service outboundHomeService;

  @task({ drop: true })
  *getLiveWorkflows() {
    try {
      let liveWorkflowsSearch = yield this.outboundHomeService.contentSearch({
        object_types: [
          objectTypes.customBot,
          objectTypes.inboundCustomBot,
          objectTypes.buttonCustomBot,
          objectTypes.triggerableCustomBot,
        ],
        states: [states.live],
        match_behaviors: [],
        app_id: this.app.id,
        per_page: 1,
      });
      this.set('liveWorkflows', liveWorkflowsSearch);
    } catch (e) {
      console.error(e);
    }
  }

  get hasNoLiveWorkflows() {
    return this.liveWorkflows?.totalCount < 1;
  }

  get hasCreatedTeamsOtherThanExamples() {
    let nonExampleTeams = this.teams.filter((team) => !team.name.includes('[Example]'));

    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    let preCannedTeams = ['Support', 'Technical'];
    return nonExampleTeams.filter((team) => !preCannedTeams.includes(team.name))?.length > 0;
  }

  showTeamsArticle = (_triggerLocation = 'dropdown_item') => {
    window.Intercom('showArticle', 197); // https://www.intercom.com/help/en/articles/197-organize-your-inbox-by-team
    this.triggerAnalytics(`how_to_setup_and_use_teams_${_triggerLocation}`, _triggerLocation);
  };

  showRouteConversationsArticle = (_triggerLocation = 'dropdown_item') => {
    window.Intercom('showArticle', 6560715); // https://www.intercom.com/help/en/articles/6560715-automatically-route-conversations-to-teammates
    this.triggerAnalytics('how_to_route_conversations_discovery_banner', _triggerLocation);
  };

  showAssignmentArticle = () => {
    window.Intercom('showArticle', 6553774); // https://www.intercom.com/help/en/articles/6553774-balanced-assignment-deep-dive
    this.triggerAnalytics('balance_workload_article_dropdown_item');
  };

  showAssignArticle = () => {
    window.Intercom('showArticle', 6561699); // https://www.intercom.com/help/en/articles/6561699-assign-conversations-to-teammates-and-teams
    this.triggerAnalytics('assign_conversations_article_dropdown_item');
  };

  showPermissionsArticle = () => {
    window.Intercom('showArticle', 3659500); // https://www.intercom.com/help/en/articles/3659500-manage-permissions-effortlessly-with-custom-roles
    this.triggerAnalytics('manage_permissions_article_dropdown_item');
  };

  get selectedTab() {
    let tab = this.hasRouteTransitioned();
    if (tab) {
      this.tab = tab;
    }
    return this.tab;
  }

  triggerAnalytics(object, section = 'dropdown_item') {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
      place: 'app.settings.teams',
      context: 'empty_state.discovery_banner.create_teams',
      section,
    });
  }
}
