/* RESPONSIBLE TEAM: team-ai-chatbot */
import Controller from '@ember/controller';
import { getOwner } from '@ember/application';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';

export default class AnswerClustersController extends Controller {
  queryParams = ['interval', 'language', { selectedTab: { as: 'tab' } }];
  @service intercomEventService;
  @service router;
  @service appService;

  @tracked interval = '90';
  @tracked language = null;
  @tracked selectedTab = 'unanswered';

  @tracked clusterIds = [];
  @tracked dismissedClusterIds = [];
  @tracked clusterCount = 0;
  @tracked dismissedCount = 0;
  @tracked isTransitioning = false;

  get dismissedCountChanged() {
    return this.dismissedCount !== this.dismissedClusterIds.length;
  }

  get unansweredCountChanged() {
    return this.clusterCount !== this.clusterIds.length;
  }

  get selectedTabCountChanged() {
    return (
      (this.selectedTab === 'dismissed' && this.dismissedCountChanged) ||
      (this.selectedTab === 'unanswered' && this.unansweredCountChanged)
    );
  }

  @action
  updateCounters(offset) {
    this.dismissedCount -= offset;
    this.clusterCount += offset;
  }

  @action
  filterByLanguage(language) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'suggested_answers_list_language_filter',
      action: 'clicked',
      place: 'answer_bot',
      section: 'operator',
      language,
    });

    this.transitionToRoute({ queryParams: { language } });
  }

  @action
  filterByInterval(interval) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'suggested_answers_list_date_interval_tab',
      action: 'clicked',
      place: 'answer_bot',
      section: 'operator',
      date_interval: interval,
    });

    this.transitionToRoute({ queryParams: { interval } });
  }

  @action
  updateSelectedTab(selectedTab) {
    this.transitionToRoute({ queryParams: { selectedTab } });

    if (this.selectedTabCountChanged) {
      let currentRouteInstance = getOwner(this).lookup(`route:${this.router.currentRouteName}`);
      let refreshTransition = currentRouteInstance.refresh();
      this.updateIsTransitioningDuringTransition.perform(refreshTransition);
    }
  }

  @task({ restartable: true })
  *updateIsTransitioningDuringTransition(transition) {
    this.isTransitioning = true;
    while (transition.isActive) {
      yield timeout(50);
    }
    this.isTransitioning = false;
  }
}
