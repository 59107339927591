/* RESPONSIBLE TEAM: team-data-interop */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { objectIcons, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import type IntlService from 'ember-intl/services/intl';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import {
  AVAILABLE_ACTION_COLUMNS,
  DEFAULT_ACTION_COLUMN_NAMES,
} from 'embercom/models/workflow-connector/constants/available-action-columns';
import CreateActionsDropdownOptionComponent from 'embercom/components/workflow-connector/create-actions-dropdown-option-component';

export default class CustomActionsIndex extends Controller {
  queryParams = ['actionId'];

  actionIcons = {
    shopify: 'shopify',
    statuspage: 'statuspage',
    stripe: 'stripe',
  } as $TSFixMe;

  @service declare store;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked usableTemplateActions = this.loadUsableTemplateActions();
  @tracked searchTerm = '';
  @tracked debouncedSearchTerm = '';
  @tracked selectedActionStateTab = 'live';
  @tracked selectedColumnNames;
  @tracked showTemplatePreviewModal = false;
  @tracked selectedActionTemplate: $TSFixMe;

  bannerIcon = objectIcons[objectTypes.outboundWebhook];
  constructor() {
    super(...arguments);

    let selectedActionsColumns = localStorage.getItem('selected_actions_columns');
    this.selectedColumnNames =
      selectedActionsColumns !== null
        ? JSON.parse(selectedActionsColumns)
        : DEFAULT_ACTION_COLUMN_NAMES;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'settings',
      tab_viewed: this.selectedActionStateTab,
    });
  }

  get currentTabLabel() {
    return this.selectedActionStateTab === 'live'
      ? this.intl.t('new-settings.app-settings.custom-actions.tabs.live')
      : this.intl.t('new-settings.app-settings.custom-actions.tabs.draft');
  }

  get app() {
    return this.appService.app;
  }
  get admin() {
    return this.app.currentAdmin;
  }

  get allWorkflowActions() {
    return (this.model as { [key: string]: any }).actions;
  }

  loadUsableTemplateActions() {
    if (!this.model) {
      return [];
    }

    if (!this.finActionsEnabled) {
      return [];
    }

    return (this.model as { [key: string]: any }).templateActions;
  }

  triggerTour() {
    window.Intercom('startTour', 351602);
  }

  @action
  async onCreateOptionSelected(selectedOption: $TSFixMe) {
    if (selectedOption === 'create-custom-action') {
      await this.createAction();
    } else if (this.canPreviewFinTemplatedActions) {
      this.selectedActionTemplate = this.store.peekRecord(
        'workflow-connector/action',
        selectedOption,
      );
      this.showTemplatePreviewModal = true;
      this.trackAnalyticsEvent(
        'open-template-action-modal',
        'custom-action',
        this.selectedActionTemplate.id,
      );
    } else {
      taskFor(this.createActionFromTemplate).perform(selectedOption);
    }
  }

  @action
  async createAction() {
    let newWorkflowAction = await this.store.createRecord('workflow-connector/action').save();

    this.trackAnalyticsEvent('created', 'custom-action', newWorkflowAction.id);
    this.router.transitionTo(
      'apps.app.settings.app-settings.custom-actions.custom-action',
      newWorkflowAction.id,
    );
  }

  @task
  *createActionFromTemplate(templateActionId: $TSFixMe): TaskGenerator<void> {
    try {
      let params = {
        app_id: this.app.id,
        template_action_id: templateActionId,
      };
      let createdAction = yield post(
        `/ember/workflow_connector/actions/create_action_from_template`,
        params,
      );
      this.trackAnalyticsEvent('created-from-template', 'custom-action', createdAction.id);
      this.router.transitionTo(
        'apps.app.settings.app-settings.custom-actions.custom-action',
        createdAction.id,
      );
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.actions.templates.error'),
      );
    }
  }

  @action
  changeActionStateTab(value: string) {
    this.selectedActionStateTab = value;
  }

  get liveActionsCount() {
    return this.allWorkflowActions.filterBy('state', 'live').length;
  }

  get draftActionsCount() {
    return this.allWorkflowActions.filterBy('state', 'draft').length;
  }

  get workflowActionsToDisplay() {
    return isPresent(this.debouncedSearchTerm)
      ? this.searchedWorkflowActions
      : this.filteredWorkflowActions;
  }

  get filteredWorkflowActions() {
    return this.allWorkflowActions.filterBy('state', this.selectedActionStateTab);
  }

  get searchedWorkflowActions() {
    let downcasedSearchTerm = this.debouncedSearchTerm.toLocaleLowerCase();
    return this.filteredWorkflowActions.filter(({ name }: { name: string }) => {
      return name.toLocaleLowerCase().includes(downcasedSearchTerm);
    });
  }

  get hasItemsToDisplay() {
    return this.allWorkflowActions.filterBy('state', this.selectedActionStateTab).length > 0;
  }

  get finActionsEnabled() {
    return this.appService.app.canUseFeature('answerbot-fin-actions');
  }

  get canPreviewFinTemplatedActions() {
    return this.appService.app.canUseFeature('answerbot-fin-preview-templated-actions');
  }

  get createActionsDropdownOptions() {
    if (this.usableTemplateActions.length > 0) {
      return [
        this.createCustomActionOption(),
        {
          heading: this.intl.t('workflow-connector.actions.templates.create'),
          items: this.usableTemplateActions.map((templateAction: $TSFixMe) => {
            return {
              icon: this.templateActionIcon(templateAction),
              iconUrl: templateAction.iconUrl,
              text: `${capitalize(templateAction.appPackageCode)}: ${templateAction.name}`,
              value: `${templateAction.id}`,
              component: CreateActionsDropdownOptionComponent,
            };
          }),
        },
      ];
    } else {
      return [this.createCustomActionOption()];
    }
  }

  get columnSelectorData(): { text: string; value: string; isSelected?: boolean }[] {
    return AVAILABLE_ACTION_COLUMNS.filter((column) => column.valuePath !== 'name').map(
      (column) => {
        return {
          text: this.intl.t(`workflow-connector.list.columns.${column.valuePath}`),
          value: column.valuePath,
          isSelected: this.selectedColumnNames.includes(column.valuePath),
        };
      },
    );
  }

  get selectedColumns() {
    return AVAILABLE_ACTION_COLUMNS.filter(
      (column) =>
        this.selectedColumnNames.includes(column.valuePath) || column.valuePath === 'name',
    );
  }

  @action
  onSelectionChange(selectedColumnNames: string[]) {
    this.selectedColumnNames = selectedColumnNames;
    localStorage.setItem('selected_actions_columns', JSON.stringify(selectedColumnNames));
  }

  templateActionIcon(action: $TSFixMe) {
    if (action.appPackageCode && this.actionIcons.hasOwnProperty(action.appPackageCode)) {
      return this.actionIcons[action.appPackageCode];
    }

    return 'new';
  }

  createCustomActionOption() {
    return {
      items: [
        {
          icon: 'new',
          text: this.intl.t('workflow-connector.actions.create-custom'),
          value: 'create-custom-action',
        },
      ],
    };
  }

  updateSearchTerm() {
    this.debouncedSearchTerm = this.searchTerm;
  }

  trackAnalyticsEvent(action: string, object: string, actionId = null) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      actionId,
    });
  }

  @action
  onChangeSearchTerm() {
    this.trackAnalyticsEvent('searched', 'custom-action');
    debounce(this, this.updateSearchTerm, ENV.APP._250MS);
  }
}
