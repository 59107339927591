/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { responseProcessingBehaviours } from 'embercom/models/data/sms/constants';

export default class SmsSettingsController extends Controller {
  @service appService;
  @tracked openSectionId = 'two-way-sms';

  get isPaywallActive() {
    return (
      this.appService.app.canUseSMS &&
      !this.appService.app.canUseSmsBeta &&
      !this.appService.app?.canUseFeature('sms')
    );
  }

  get twoWaySmsState() {
    return this.model.smsOutboundSettings &&
      this.model.smsOutboundSettings.responseProcessingBehaviour ===
        responseProcessingBehaviours.deflectToInbox
      ? 'On'
      : 'Off';
  }

  @action
  onOpenSectionChange(sectionId) {
    this.openSectionId = sectionId;
  }

  get isAlphanumericSenderInfoDisabled() {
    // See: https://github.com/intercom/intercom/issues/293852
    return this.model.smsPhoneNumbers.some((number) => number.countryCode === 'AU');
  }
}
