/* RESPONSIBLE TEAM: team-product-exploration */
import Controller from '@ember/controller';
import { SETTINGS_NAV_ITEMS } from 'embercom/components/new-settings/navigation/submenu-items';

export default class IndexController extends Controller {
  queryParams = [];

  get settingsStructure() {
    return SETTINGS_NAV_ITEMS.filter((item) => !item.hasNoChildren);
  }
}
