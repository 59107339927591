/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
import { isEmpty } from '@ember/utils';
import { action, computed } from '@ember/object';
import { readOnly, equal, not, alias, mapBy } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import IntegrateBaseController from 'embercom/controllers/apps/app/integrations-hub/integrate-base-controller';
import intersection from 'embercom/lib/intersection';
import { INSTALLATION_DOCS_URL } from 'embercom/lib/installation-docs-url';
import {
  INSTALL_TYPE,
  AUDIENCE,
  LOGGED_IN_CODE_DROPDOWN_ITEMS,
  LOGGED_OUT_CODE_DROPDOWN_ITEMS,
  THIRD_PARTY_DROPDOWN_ITEMS,
} from 'embercom/lib/messenger/installation-constants';

export default IntegrateBaseController.extend({
  appController: controller('apps/app'),
  app: readOnly('appController.model'),

  exampleType: 'users',

  exampleTypeIsUsers: equal('exampleType', 'users'),

  installationIsAnonymous: not('exampleTypeIsUsers'),

  userSources: alias('model.userSources'),

  userSourceNames: mapBy('userSources', 'name'),
  webUserSourceNames: ['web', 'javascript', 'wordpress', 'rails', 'single_page'],

  selectedLoggedInInstallMethod: null,
  selectedLoggedOutInstallMethod: null,

  LOGGED_OUT_AUDIENCE: AUDIENCE.LOGGED_OUT,
  LOGGED_IN_AUDIENCE: AUDIENCE.LOGGED_IN,
  INSTALL_TYPE_CODE: INSTALL_TYPE.CODE,

  baseAnalyticsMetadata: {
    place: 'settings_installation',
  },

  loggedOutDropdownItems: computed('selectedLoggedOutInstallMethod', function () {
    if (this.selectedLoggedOutInstallMethod === INSTALL_TYPE.THIRD_PARTY) {
      return THIRD_PARTY_DROPDOWN_ITEMS;
    }

    return LOGGED_OUT_CODE_DROPDOWN_ITEMS;
  }),

  loggedInDropdownItems: computed('selectedLoggedInInstallMethod', function () {
    if (this.selectedLoggedInInstallMethod === INSTALL_TYPE.THIRD_PARTY) {
      return THIRD_PARTY_DROPDOWN_ITEMS;
    }

    return LOGGED_IN_CODE_DROPDOWN_ITEMS;
  }),

  userSourcePresent: computed('userSourceNames', 'webUserSourceNames', function () {
    let matchingUserSources = intersection(this.userSourceNames, this.webUserSourceNames);
    return !isEmpty(matchingUserSources);
  }),

  setLoggedInInstallMethod: action(function (method) {
    this.set('selectedLoggedInInstallMethod', method);
  }),

  setLoggedOutInstallMethod: action(function (method) {
    this.set('selectedLoggedOutInstallMethod', method);
  }),

  sdkApp: readOnly('app.sdkApps.firstObject'),
  enforcesEncryptedMode: readOnly('sdkApp.enforcesEncryptedMode'),
  docsUrl: INSTALLATION_DOCS_URL,
});
