/* RESPONSIBLE TEAM: team-customer-data-platform */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import AttributeSettingsController from 'embercom/controllers/apps/app/settings/base/attribute-settings-controller';

const ATTRIBUTE_TYPES_TO_HIDE = ['role', 'anonymous'];

export default class PeopleDataController extends AttributeSettingsController {
  @service attributeService;
  @service appService;
  @service intercomEventService;

  get attributes() {
    return this.attributeService.allUserAttributes
      .filter((setting) => !ATTRIBUTE_TYPES_TO_HIDE.includes(setting.attribute.type))
      .concat(this.attributeService.userRelationshipDisplayableAttributes);
  }

  get archivedAttributes() {
    return this.archivedAttributesWithSettings.filterBy('attribute.isUser');
  }

  @action
  showCustomAttributesArticle() {
    window.Intercom('showArticle', 179); // https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom#how-do-i-do-it
  }

  @action
  showCustomAttributesArticleBanner() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'link_learn_custom_attributes',
      context: 'onboarding_guide_library.discovery_banner.settings.people_data',
      place: 'people_data',
      section: 'discovery_banner',
    });
    this.showCustomAttributesArticle();
  }
}
