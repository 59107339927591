/* RESPONSIBLE TEAM: team-ai-chatbot */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import RouteRegexes from 'embercom/lib/route-regexes';
import type Router from '@ember/routing/router-service';
import type AutomationNavCounts from 'embercom/services/automation-nav-counts';

export default class FinAIAgentController extends Controller {
  queryParams = [];

  @service declare router: Router;
  @service declare appService: any;
  @service declare navbarCollapsingService: any;
  @service declare contentImportService: any;
  @service declare automationNavCounts: AutomationNavCounts;

  get isOnFinAIAgentSetupRoute() {
    return RouteRegexes.finAIAgentSetup.test(this.router.currentRouteName);
  }

  get isOnFinAIAgentContentRoute() {
    return RouteRegexes.finAIAgentContent.test(this.router.currentRouteName);
  }

  get isOnFinAIAgentCustomAnswersRoute() {
    return RouteRegexes.finAIAgentCustomAnswers.test(this.router.currentRouteName);
  }

  get isOnFinAIAgentProfilesRoute() {
    return RouteRegexes.finAIAgentProfiles.test(this.router.currentRouteName);
  }

  get isOnFinAIAgentSettingsRoute() {
    return RouteRegexes.finAIAgentSettings.test(this.router.currentRouteName);
  }

  get isOnFinAIAgentAICategoriesRoute() {
    return RouteRegexes.finAIAgentAICategories.test(this.router.currentRouteName);
  }

  get numberOfPendingItemsToReview() {
    return this.contentImportService.aiContentReviewSummary.pending;
  }

  get canUseContentApprovalWorkflow() {
    if (this.contentImportService.finConversationContentSettings) {
      if (this.contentImmediatelyAvailableToFinAndPendingItemsToReview) {
        return true;
      }
      return !this.contentImportService.finConversationContentSettings.immediatelyAvailableToFin;
    }
    return false;
  }

  get contentImmediatelyAvailableToFinAndPendingItemsToReview() {
    return (
      this.contentImportService.finConversationContentSettings.immediatelyAvailableToFin &&
      this.contentImportService.aiContentReviewSummary.pending > 0
    );
  }

  get allAnswersCount() {
    return this.automationNavCounts.allAnswersCount;
  }

  get isFinContentPageDeprecated() {
    return this.appService.app.finContentPageDeprecated && this.appService.app.canUseKnowledgeHub;
  }
}
