/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import { equal } from '@ember/object/computed';
import SdkBaseController from 'embercom/controllers/apps/app/settings/sdk-base-controller';

export default SdkBaseController.extend({
  exampleFormat: 'kotlin',
  exampleIsKotlin: equal('exampleFormat', 'kotlin'),
  exampleIsReactNative: equal('exampleFormat', 'reactnative'),
});
