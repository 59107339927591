/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CollectionsController extends Controller {
  @service declare appService: any;
  @service declare helpCenterService: any;

  declare model: {
    helpCenterSite: any;
    allCollections: any;
    allArticlesSummaries: any;
  };

  sortKey = ['order'];
  queryParams = ['collectionId'];

  @tracked collectionId: string | undefined;

  get app() {
    return this.appService.app;
  }

  get helpCenterSite() {
    return this.model.helpCenterSite;
  }

  get collectionsWithoutHome() {
    return this.allCollections.rejectBy('isHome', true);
  }

  get collections() {
    return this.collectionsWithoutHome.sortBy('order');
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get defaultLocaleId() {
    return this.helpCenterSite.locale;
  }

  get allCollections() {
    return this.model.allCollections;
  }

  get allArticlesSummaries() {
    return this.model.allArticlesSummaries;
  }

  get collectionTree() {
    return null;
  }

  get selectedCollection() {
    if (!this.collectionId) {
      return null;
    }

    return this.collectionsWithoutHome.findBy('id', this.collectionId);
  }
}
