/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-controllers */
import ReportBase from 'embercom/controllers/apps/app/reports/base/report';
import {
  MEDIAN_TIME_TO_CLOSE,
  MEDIAN_FIRST_RESPONSE_TIME_NEW,
} from 'embercom/lib/reporting/queries';
import { computed } from '@ember/object';
import { readOnly, not, alias, or } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default ReportBase.extend({
  intercomEventService: service(),
  appService: service(),
  app: alias('appService.app'),
  isAnyChannelInstalled: or(
    'app.hasAnyInstalledAtDate',
    'app.first_identified_request_at',
    'app.verifiedLoggedInInstallAt',
    'app.first_anonymous_request_at',
    'app.verifiedLoggedOutInstallAt',
  ),

  timezone: readOnly('app.timezone'),

  inbox: readOnly('app.inboxProduct'),
  hasInbox: not('inbox.inactive'),

  messages: readOnly('app.messagesProduct'),
  hasMessages: not('messages.inactive'),

  customBots: readOnly('app.customBotsProduct'),
  hasCustomBots: not('customBots.inactive'),

  canUseFree: readOnly('app.canUseFree'),

  answerBot: readOnly('app.answerBotProduct'),
  hasAnswerBot: not('answerBot.inactive'),

  articles: readOnly('app.articlesProduct'),
  hasArticles: not('articles.inactive'),

  medianTimeToCloseQuery: computed('settings.reportingExcludeOooHours', function () {
    return MEDIAN_TIME_TO_CLOSE({
      withinOfficeHours: this.settings.reportingExcludeOooHours,
    });
  }),
  medianFirstResponseTimeQuery: computed('settings.reportingExcludeOooHour', function () {
    return MEDIAN_FIRST_RESPONSE_TIME_NEW({
      withinOfficeHours: this.settings.reportingExcludeOooHours,
    });
  }),

  hideComparison: computed(
    'range.{comparisonStartIsMoreThanTwoYearsAgo,comparisonStartMoment}',
    'app.created_at',
    function () {
      return (
        this.range.comparisonStartIsMoreThanTwoYearsAgo ||
        this.range.comparisonStartMoment.isBefore(this.app.created_at)
      );
    },
  ),
  actions: {
    updateRange(range) {
      this.reportingService.updateRange(range);

      this._recordFilteredByDateAnalyticsEvent();
    },

    onStartInboxTrial() {
      return this.transitionToRoute('apps.app.inbox');
    },
    transitionToNewArticle() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_an_article',
      });
      return this.transitionToRoute('apps.app.articles.articles.new');
    },
    transitionToNewAnswer() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_an_answer',
      });
      return this.transitionToRoute('apps.app.automation.resolution-bot.answers.new');
    },
    transitionToPerformanceReport() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'see_performance_report',
      });
      return this.transitionToRoute('apps.app.reports.customer-support.conversations-flexible');
    },
    transitionToArticlesReport() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'see_articles_report',
      });
      return this.transitionToRoute('apps.app.reports.customer-support.articles');
    },
    transitionToAnswerBotReport() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'see_answer_bot_report',
      });
      return this.transitionToRoute('apps.app.reports.customer-support.resolution-bot');
    },
  },
});
