/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import { alias } from '@ember/object/computed';
import IdentityVerificationBasePlatform from 'embercom/controllers/apps/app/settings/identity-verification/identity-verification-base-platform';

export default IdentityVerificationBasePlatform.extend({
  sdkApp: alias('app.iosSdkApps.firstObject'),
});
