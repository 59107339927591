/* RESPONSIBLE TEAM: team-product-setup */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { INSTALLATION_PLATFORMS } from 'embercom/components/installation-new/constants';

export default class InstallationController extends Controller {
  queryParams = ['selectedTab', 'selectedAccordion'];
  @service declare appService: $TSFixMe;
  @tracked iosSdkApp = this.app.iosSdkApps.firstObject;
  @tracked androidSdkApp = this.app.androidSdkApps.firstObject;
  @tracked iosApiKey = '';
  @tracked androidApiKey = '';

  selectedTab: INSTALLATION_PLATFORMS = INSTALLATION_PLATFORMS.WEB;
  selectedAccordion: 'messenger-setup' | 'idv-setup' = 'messenger-setup';
  androidApiSecret = '';
  iosApiSecret = '';
  iosGcmKey = '';
  androidGcmKey = '';

  get app() {
    return this.appService.app;
  }

  @action
  setSelectedTab(tab: INSTALLATION_PLATFORMS) {
    this.set('selectedTab', tab);
  }

  @action
  setSelectedAccordion(accordion: 'messenger-setup' | 'idv-setup') {
    this.set('selectedAccordion', accordion);
  }

  @action
  deleteSdkApp() {
    this.set('model', null);
  }
}
