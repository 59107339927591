/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
import { computed, observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias, not } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import { SELF_SERVE_CHURN_SURVEY_ID } from 'embercom/lib/billing/surveys';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';

let GeneralSettingsController = Controller.extend({
  intercomEventService: service(),
  notificationsService: service(),
  modelDataCacheService: service(),
  intl: service(),
  router: service(),
  customerService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  customer: alias('customerService.customer'),
  appsController: controller('apps'),
  appsControllerModel: alias('appsController.model'),
  timezones: alias('model.timezones'),

  appService: service(),
  app: alias('appService.app'),

  hasAppPermissions: computed('app', function () {
    return this.app.canUseFeature('app_permissions');
  }),
  isNotDirty: not('model.hasDirtyAttributes'),
  useCustomTimezone: false,
  timezoneOptions: computed('customTimezoneName', 'app', 'timezones', function () {
    let customTimezone = this.customTimezoneName;
    let timezones = this.timezones ? [...this.timezones] : [];
    if (typeof customTimezone === 'string' && customTimezone.length > 0) {
      return timezones.concat({
        name: customTimezone,
        description: customTimezone,
      });
    } else {
      return timezones;
    }
  }),
  selectedTimezoneDidChange: observer({
    dependentKeys: ['selectedTimezone'],

    fn() {
      if (this.model) {
        this.set('model.time_zone_name', this.get('selectedTimezone.name'));
      }
    },

    sync: true,
  }),
  changedTestAppUse: false,

  async saveAfterConfirmation() {
    try {
      await this.model.save();
      this.modelDataCacheService.fetch('app', this.app.id);
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.general.save-button.success-notification'),
      );
      this.set('app.name', this.get('model.name'));
      this.set('app.companies_enabled', this.get('model.companies_enabled'));
      if (this.changedTestAppUse) {
        GeneralSettingsController.reloadWindow();
      }
    } catch (err) {
      let notificationText;
      let responseJSON = err.jqXHR.responseJSON;
      if (responseJSON && responseJSON.errors[0]) {
        notificationText = responseJSON.errors[0];
      } else {
        notificationText = this.intl.t('settings.general.save-button.failure-notification');
      }
      this.notificationsService.notifyError(notificationText);
    }
  },

  actions: {
    setAuths(auth) {
      this.set('auths', this.get(auth));
    },
    async save() {
      return this.saveAfterConfirmation();
    },
    destroyApp() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'delete_app_button',
        context: 'from_settings',
      });

      if (
        this.customer.subscriptionCancelledAt ||
        this.customer.subscriptionWillBeCancelled ||
        this.customer.hasNoActiveSubscription ||
        this.app.isDeveloperWorkspace
      ) {
        this.modalService.openModal('settings/modals/delete-app-modal', this.app);
      } else if (this.app.canSeeSelfServeChurnSurvey) {
        // The last step of the new self serve churn Survey will
        // redirect customers to their cancellation page.
        startSurvey(SELF_SERVE_CHURN_SURVEY_ID);
      } else {
        this.router.transitionTo('apps.app.billing.cancel'); // Fallback for AU / EU workspaces
      }
    },
    toggleTestAppUse() {
      this.toggleProperty('model.uses_test_app');
      this.toggleProperty('changedTestAppUse');
    },
    updateAuthWith(methods) {
      if (this.allAuth !== methods) {
        return;
      }
      this.set('auths', methods);
    },
  },
}).reopenClass({
  reloadWindow() {
    document.location.reload(true);
  },
});

export default GeneralSettingsController;
