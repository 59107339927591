/* RESPONSIBLE TEAM: team-tickets-1 */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class WorkloadManagementController extends Controller {
  queryParams = ['tab', 'highlight'];

  @tracked tab = 'workspace';
  @tracked highlight: string | null = null;

  get selectedTab() {
    return this.tab;
  }

  @action
  changeTab(tab: string) {
    this.set('tab', tab);
    this.tab = tab;
  }
}
