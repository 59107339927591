/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { putRequest, request } from 'embercom/lib/inbox/requests';
import { extractInvalidDomainPatterns } from 'embercom/lib/domains';

export default class AttachmentSettings extends Controller {
  @service appService;
  @service notificationsService;
  @service intl;

  @tracked openSectionId = 'outbound_email';

  async loadURLVerificationSettings() {
    try {
      let response = await request(
        `/ember/trusted_domains_settings/show?app_id=${this.appService.app.id}`,
      );
      response = await response.json();

      this.useLinkWarnings = response.enabled;
      this.localTrustedDomainsList = response.trusted_domains;
      this.savedTrustedDomainsList = response.trusted_domains;

      this.updateTrustedDomainSettings(response);
      if (window.location.hash === '#links') {
        this.openSectionId = 'links';
      }
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.load-failed'),
      });
    }
  }

  get canSave() {
    let modelCanBeSaved =
      !this.model.allowedAttachmentFiletypesEnabled || this.model.allowedAttachmentFiletypesEnabled;
    return (
      !this.saveTask.isRunning &&
      this.model.hasDirtyAttributes &&
      modelCanBeSaved &&
      this.model.isFiletypeListValid
    );
  }

  get canSaveTrustedDomainsSettings() {
    let hasTrustedDomainListBeenUpdated = this.hasTrustedDomainListBeenUpdated();
    return (
      (this.hasLinkSectionBeenUpdated || hasTrustedDomainListBeenUpdated) &&
      !this.showLinkModal &&
      !this.hasUserCancelledLinkToggle &&
      !this.saveLinkTask.isRunning &&
      this.invalidTrustedDomainsList.length === 0
    );
  }

  @task
  *saveTask() {
    try {
      yield this.model.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.attachment-settings.save-successful'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.trusted-domains-error-message'),
      });
    }
  }

  @task
  *saveLinkTask() {
    try {
      let payload = {
        app_id: this.appService.app.id,
        enabled: this.useLinkWarnings,
      };

      if (this.useLinkWarnings) {
        payload.trusted_domains = this.localTrustedDomainsList;
      }

      let response = yield putRequest(`/ember/trusted_domains_settings/update`, payload);

      response = yield response.json();

      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.attachment-settings.save-successful'),
      );
      this.hasLinkSectionBeenUpdated = false;
      this.localTrustedDomainsList = response.trusted_domains;
      this.savedTrustedDomainsList = response.trusted_domains;
      this.updateTrustedDomainSettings(response);
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.save-failed'),
      });
    }
  }

  hasTrustedDomainListBeenUpdated() {
    let hasUpdated = this.localTrustedDomainsList !== this.savedTrustedDomainsList;
    if (hasUpdated) {
      this.hasUserCancelledLinkToggle = false;
    }
    return hasUpdated;
  }

  updateTrustedDomainSettings(response) {
    if (!response.updated_by_admin_id) {
      this.trustedDomainSettings = {
        enabled: response.enabled,
        updatedAt: response?.updated_at ? response.updated_at : '',
        adminName: '',
      };
      return;
    }
    let id = `${response.updated_by_admin_id}`;
    let admin = this.appService.app.admins.findBy('id', id);
    this.trustedDomainSettings = {
      enabled: response.enabled,
      updatedAt: response?.updated_at ? response.updated_at : '',
      adminName: admin?.name ? admin.name : '',
    };
  }

  @tracked useLinkWarnings = true;
  @tracked trustedDomainSettings = {
    enabled: true,
    updatedAt: '',
    adminName: '',
  };
  @tracked showLinkModal = false;
  @tracked hasUserCancelledLinkToggle = false;
  @tracked hasLinkSectionBeenUpdated = false;
  @tracked savedTrustedDomainsList = '';
  @tracked localTrustedDomainsList = '';
  @tracked invalidTrustedDomainsList = [];
  @tracked showRiskConfirmation = false;

  @action
  updateTrustedDomains(value) {
    this.localTrustedDomainsList = value;
    this.invalidTrustedDomainsList = extractInvalidDomainPatterns(this.localTrustedDomainsList);
  }

  @action
  onOpenSectionChange(sectionId) {
    this.openSectionId = sectionId;
  }

  @action
  onAllowListChange(allowList) {
    this.model.set('allowList', allowList);
  }

  @action
  disableAttachUploadSettings() {
    this.model.set('attachUploadsInline', false);
  }

  @action
  enableAttachUploadSettings() {
    this.model.set('attachUploadsInline', true);
  }

  @action
  toggleUserAttachmentSettingsEnabled() {
    let newValue = !this.model.userConversationAttachmentsEnabled;

    this.model.set('userConversationAttachmentsEnabled', newValue);
    this.model.set('userConversationCameraEnabled', newValue);
    this.model.set('userConversationMediaEnabled', newValue);
    this.model.set('userConversationGifsEnabled', newValue);
    this.model.set('userConversationFilesEnabled', newValue);

    if (!newValue) {
      this.model.set('allowedAttachmentFiletypesEnabled', false);
      this.model.set('allowedAttachmentFiletypesAgreedToRisk', false);
    }
  }

  @action
  toggleOtherFiles() {
    if (!this.model.allowedAttachmentFiletypesEnabled) {
      this.openOtherFilesModal();
    } else {
      this.model.set('allowedAttachmentFiletypesEnabled', false);
      // The user turned off the other files, so lets reset the agreedToRisk
      this.model.set('allowedAttachmentFiletypesAgreedToRisk', false);
    }
  }

  @action
  toggleUseLinkWarnings() {
    if (this.useLinkWarnings) {
      this.openLinkModal();
    } else {
      this.enableUseTrustedDomainsForUrlVerification();
    }
  }

  @action
  enableOtherFiles() {
    this.model.set('allowedAttachmentFiletypesEnabled', true);
    this.model.set('allowedAttachmentFiletypesAgreedToRisk', true);
    this.closeOtherFilesWarningModal();
  }

  @action
  enableUseTrustedDomainsForUrlVerification() {
    this.useLinkWarnings = true;
    this.hasLinkSectionBeenUpdated = true;
  }

  @action
  disableUseTrustedDomainsForUrlVerification() {
    this.model.set('useTrustedDomainsForUrlVerification', false);
    this.useLinkWarnings = false;
    this.closeLinkModalAndEnableSave();
  }

  @action
  openOtherFilesModal() {
    this.showRiskConfirmation = true;
  }

  @action
  openLinkModal() {
    this.showLinkModal = true;
  }

  @action
  closeLinkModalAndDisableSave() {
    this.hasUserCancelledLinkToggle = true;
    this.showLinkModal = false;
    this.hasLinkSectionBeenUpdated = false;
  }

  @action
  closeLinkModalAndEnableSave() {
    this.useLinkWarnings = false;
    this.hasUserCancelledLinkToggle = false;
    this.hasLinkSectionBeenUpdated = true;
    this.showLinkModal = false;
  }

  @action
  cancelOtherFiles() {
    this.model.set('allowedAttachmentFiletypesEnabled', false);
    this.model.set('allowedAttachmentFiletypesAgreedToRisk', false);
    this.closeOtherFilesWarningModal();
  }

  @action
  closeOtherFilesWarningModal() {
    this.showRiskConfirmation = false;
  }
}
