/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import { Promise as EmberPromise } from 'rsvp';
import { reads, readOnly } from '@ember/object/computed';
import Controller from '@ember/controller';
import ErrorHandling from 'embercom/components/mixins/articles/error-handling';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { inject as service } from '@ember/service';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';

export default Controller.extend(ErrorHandling, EventTracking, {
  notificationsService: service(),
  permissionsService: service(),
  appService: service(),
  helpCenterService: service(),
  intl: service(),
  intercomConfirmService: service(),
  app: readOnly('appService.app'),
  site: readOnly('helpCenterService.site'),
  collection: reads('model'),
  articles: reads('model.sortedArticles'),

  activeLocale: readOnly('helpCenterService.currentlySelectedLocaleId'),

  _saveCollection(collection) {
    let action = collection.get('id') === null ? 'created' : 'saved';

    return collection
      .saveIfDirty()
      .then(() => {
        collection.set('shouldFocusName', false);
        this.trackEducateCollectionOrSectionEvent(collection, { action, place: 'collection' });
      })
      .catch((response) => {
        this.handleServerError(
          response,
          `There was a problem saving your ${collection.get('type')}`,
        );
        collection.rollbackAttributes();
      });
  },

  _deleteArticleFolder(folder) {
    return this.permissionsService
      .checkPermission(CAN_MANAGE_ARTICLES_PERMISSION)
      .then(async () => {
        let name = folder.get('name');
        let type = folder.get('type');

        let confirmOptions = this._buildConfirmDeleteOptions(folder, type);
        let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
        if (!isConfirmed) {
          return;
        }
        this.trackEducateCollectionOrSectionEvent(folder, {
          action: 'deleted',
          place: 'collection',
        });
        return folder
          .destroyRecord()
          .then(() => {
            let confirmationMessage =
              type === 'collection'
                ? this.intl.t('articles.collections.confirm_delete.collection.success_message')
                : this.intl.t('articles.collections.confirm_delete.section.success_message');
            this.notificationsService.notifyConfirmation(confirmationMessage);
            return new EmberPromise((successHandler) => successHandler());
          })
          .catch((response) => {
            this.handleServerError(response, `There was a problem deleting '${name}'`);
          });
      });
  },

  _buildConfirmDeleteOptions(folder, type) {
    let title =
      type === 'collection'
        ? this.intl.t('articles.collections.confirm_delete.collection.title')
        : this.intl.t('articles.collections.confirm_delete.section.title');
    let confirmButtonText =
      type === 'collection'
        ? this.intl.t('articles.collections.confirm_delete.collection.confirm_button')
        : this.intl.t('articles.collections.confirm_delete.section.confirm_button');

    let body;
    if (folder.get('hasArticles')) {
      body =
        type === 'collection'
          ? this.intl.t('articles.collections.confirm_delete.collection.body_with_article')
          : this.intl.t('articles.collections.confirm_delete.section.body_with_article');
    } else {
      body =
        type === 'collection'
          ? this.intl.t('articles.collections.confirm_delete.collection.body_without_article')
          : this.intl.t('articles.collections.confirm_delete.section.body_without_article');
    }

    return {
      title,
      confirmButtonText,
      cancelButtonText: this.intl.t('articles.collections.confirm_delete.cancel'),
      primaryButtonType: 'primary-destructive',
      body,
    };
  },

  actions: {
    saveFolder(collection) {
      if (collection.hasDirtyAttributes) {
        this._saveCollection(collection);
      }
    },

    deleteSection(section) {
      this._deleteArticleFolder(section);
    },

    deleteCollection(collection) {
      this._deleteArticleFolder(collection).then(() => {
        this.transitionToRoute('apps.app.articles.site.collections');
      });
    },
  },
});
