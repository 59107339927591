/* RESPONSIBLE TEAM: team-customer-data-platform */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { reads } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
import NumberPagination from 'embercom/controllers/mixins/number-pagination';
import PageLoader from 'embercom/controllers/mixins/users-and-companies/page-loader';
import { inject as service } from '@ember/service';

let BlockedUsersController = Controller.extend(NumberPagination, PageLoader, {
  appController: controller('apps/app'),
  app: reads('appController.model'),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),

  actions: {
    unblockUser(blockedUser) {
      this.modalService.openModal('settings/unblock-blocked-user-modal', blockedUser);
    },
  },
});

export default BlockedUsersController;
