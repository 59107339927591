/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { computed } from '@ember/object';
import { readOnly, alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import moment from 'moment-timezone';
import { greaterThanProperty } from '@intercom/pulse/lib/computed-properties';

function momentToDateJson(m) {
  return { day: m.date(), month: m.month() + 1, year: m.year() };
}

export default Controller.extend({
  appService: service(),
  // eslint-disable-next-line @intercom/intercom/no-legacy-modal
  modalService: service(),
  app: readOnly('appService.app'),
  query: alias('model'),
  displayedEvents: alias('model'),

  dateToMoment(date) {
    let newDate = { day: date.day, month: date.month - 1, year: date.year };
    return moment(newDate);
  },
  fromDate: { day: null, month: null, year: null },
  fromMoment: computed('fromDate.{day,month,year}', function () {
    return this.dateToMoment(this.fromDate);
  }),
  toDate: { day: null, month: null, year: null },
  toMoment: computed('toDate.{day,month,year}', function () {
    return this.dateToMoment(this.toDate);
  }),
  requestedDays: computed('fromMoment', 'toMoment', function () {
    return Math.abs(this.toMoment.diff(this.fromMoment, 'days'));
  }),
  maxDays: 30,
  notValidRequest: greaterThanProperty('requestedDays', 'maxDays'),

  setupDate() {
    let toMoment = moment();
    let fromMoment = toMoment.clone().subtract(7, 'days');

    this.set('fromDate', momentToDateJson(fromMoment));
    this.set('toDate', momentToDateJson(toMoment));
  },

  afterSetup() {
    this.setupDate();
    this.send('load');
  },
  actions: {
    export() {
      this.modalService.openModal('settings/modals/logs-export', {
        appId: this.get('app.id'),
        from: this.fromMoment,
        to: this.toMoment,
      });
    },
    load() {
      if (this.notValidRequest) {
        return;
      }
      this.get('query.loadBetweenDates').perform(this.fromMoment.unix(), this.toMoment.unix());
    },
  },
});
