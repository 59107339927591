/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class CollectionsController extends Controller {
  @service appService;
  @service helpCenterService;

  sortKey = ['order'];
  queryParams = ['collectionId'];

  get app() {
    return this.appService.app;
  }

  get helpCenterSite() {
    return this.helpCenterService.site;
  }

  get collectionsWithoutHome() {
    return this.allCollections.rejectBy('isHome', true);
  }

  get collections() {
    return this.collectionsWithoutHome.sortBy('order');
  }

  get selectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get defaultLocaleId() {
    return this.helpCenterSite.locale;
  }

  get allCollections() {
    if (this.app.canUseArticlesSubSections) {
      return this.model[0];
    }
    return this.model;
  }

  get allArticlesSummaries() {
    return this.model[1];
  }

  get collectionTree() {
    return null;
  }

  get selectedCollection() {
    if (!this.collectionId) {
      return null;
    }

    return this.collectionsWithoutHome.findBy('id', this.collectionId);
  }
}
