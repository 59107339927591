/* RESPONSIBLE TEAM: team-data-interop */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';
import ENV from 'embercom/config/environment';
import { isPresent } from '@ember/utils';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { objectIcons, objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import type Action from 'embercom/models/workflow-connector/action';
import { taskFor } from 'ember-concurrency-ts';

export default class WorkflowConnectorActionsIndex extends Controller {
  queryParams: string[] = ['actionId'];

  actionIcons: { [key: string]: string } = {
    shopify: 'shopify',
    statuspage: 'statuspage',
    stripe: 'stripe',
  };

  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;

  @tracked searchTerm = '';
  @tracked debouncedSearchTerm = '';
  @tracked selectedActionStateTab = 'live';
  @tracked showSideDrawer = false;
  @tracked selectedWorkflowAction: any = null;

  bannerIcon = objectIcons[objectTypes.outboundWebhook];
  constructor() {
    super(...arguments);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'settings',
      tab_viewed: this.selectedActionStateTab,
    });
  }

  get app() {
    return this.appService.app;
  }
  get admin() {
    return this.app.currentAdmin;
  }

  get allWorkflowActions() {
    return (this.model as { [key: string]: any }).actions;
  }

  get usableTemplateActions() {
    if (!this.finActionsEnabled) {
      return [];
    }

    return (this.model as { [key: string]: any }).templateActions;
  }

  get liveActionsCount() {
    return this.allWorkflowActions.filterBy('state', 'live').length;
  }

  get draftActionsCount() {
    return this.allWorkflowActions.filterBy('state', 'draft').length;
  }

  get archivedActionsCount() {
    return this.allWorkflowActions.filterBy('state', 'archived').length;
  }

  get workflowActionsToDisplay() {
    return isPresent(this.debouncedSearchTerm)
      ? this.searchedWorkflowActions
      : this.filteredWorkflowActions;
  }

  get filteredWorkflowActions() {
    return this.allWorkflowActions.filterBy('state', this.selectedActionStateTab);
  }

  get searchedWorkflowActions() {
    let downcasedSearchTerm = this.debouncedSearchTerm.toLocaleLowerCase();
    return this.filteredWorkflowActions.filter(({ name }: { name: string }) => {
      return name.toLocaleLowerCase().includes(downcasedSearchTerm);
    });
  }

  get hasItemsToDisplay() {
    return this.allWorkflowActions.filterBy('state', this.selectedActionStateTab).length > 0;
  }

  get finActionsEnabled() {
    return this.appService.app.canUseFeature('answerbot-fin-actions');
  }

  get createActionsDropdownOptions() {
    if (this.usableTemplateActions.length > 0) {
      return [
        this.createCustomActionOption(),
        {
          heading: this.intl.t('workflow-connector.actions.templates.create'),
          items: this.usableTemplateActions.map((templateAction: Action) => {
            return {
              icon: this.templateActionIcon(templateAction),
              text: `${capitalize(templateAction.appPackageCode)}: ${templateAction.name}`,
              value: `${templateAction.id}`,
            };
          }),
        },
      ];
    } else {
      return [this.createCustomActionOption()];
    }
  }

  templateActionIcon(action: Action) {
    if (action.appPackageCode && this.actionIcons.hasOwnProperty(action.appPackageCode)) {
      return this.actionIcons[action.appPackageCode];
    }

    return 'new';
  }

  createCustomActionOption() {
    return {
      items: [
        {
          icon: 'new',
          text: this.intl.t('workflow-connector.actions.create-custom'),
          value: 'create-custom-action',
        },
      ],
    };
  }

  updateSearchTerm() {
    this.debouncedSearchTerm = this.searchTerm;
  }

  trackAnalyticsEvent(action: string, object: string, actionId: string | null = null) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      actionId,
    });
  }

  @action
  async onCreateOptionSelected(selectedOption: string) {
    if (selectedOption === 'create-custom-action') {
      await this.createAction();
    } else {
      taskFor(this.createActionFromTemplate).perform(selectedOption);
    }
  }

  @action
  async createAction() {
    let newWorkflowAction = await this.store.createRecord('workflow-connector/action').save();

    this.trackAnalyticsEvent('created', 'custom-action', newWorkflowAction.id);
    this.router.transitionTo(
      'apps.app.settings.workflow-connector-actions.custom-action',
      newWorkflowAction.id,
    );
  }

  @action
  changeActionStateTab(value: string) {
    this.selectedActionStateTab = value;
  }

  @action
  onChangeSearchTerm() {
    this.trackAnalyticsEvent('searched', 'custom-action');
    debounce(this, this.updateSearchTerm, ENV.APP._250MS);
  }

  @task
  *createActionFromTemplate(templateActionId: string) {
    try {
      let params = {
        app_id: this.app.id,
        template_action_id: templateActionId,
      };
      let createdAction: Action = yield post(
        `/ember/workflow_connector/actions/create_action_from_template`,
        params,
      );
      this.trackAnalyticsEvent('created-from-template', 'custom-action', createdAction.id);
      this.router.transitionTo(
        'apps.app.settings.workflow-connector-actions.custom-action',
        createdAction.id,
      );
    } catch (exception) {
      this.notificationsService.notifyError(
        this.intl.t('workflow-connector.actions.templates.error'),
      );
    }
  }
}
