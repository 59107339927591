/* RESPONSIBLE TEAM: team-knowledge-interop */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  type SortDirection,
  type Predicate,
  type TagOperator,
} from 'embercom/lib/knowledge-hub/constants';
import { type EntityType } from 'embercom/models/data/entity-types';
import {
  type SyncIssuesFilterOptions,
  type FolderFilterOption,
} from 'embercom/lib/content-service/search-api';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { inject as service } from '@ember/service';
import { IN_OPERATOR } from 'embercom/lib/outbound/constants';

export default class AllContent extends Controller {
  @service declare knowledgeHubService: KnowledgeHubService;

  queryParams = [
    { searchTerm: 'search' },
    'filtersApplied',
    'activeFilter',
    { selectedTypes: 'types' },
    'urlSourceId',
    { selectedHelpCenterIds: 'helpCenterIds' },
    'predicates',
    'locale',
    'status',
    'chatbotState',
    'copilotState',
    'createdBy',
    'lastUpdatedBy',
    { selectedFolderFilterOption: 'folder_filter_option' },
    { selectedSyncIssueOption: 'needs_review' },
    { sortByParam: 'sort_by' },
    { sortDirectionParam: 'sort_direction' },
    'tagIds',
    'tagOperator',
  ];

  @tracked activeFilter?: string;
  @tracked declare filtersApplied?: string;
  @tracked declare searchTerm?: string;
  @tracked declare locale: string | null;
  @tracked declare predicates?: string;
  @tracked declare status: string | null;
  @tracked declare chatbotState?: string;
  @tracked declare copilotState?: string;
  @tracked declare createdBy?: string;
  @tracked declare selectedTypes?: string;
  @tracked declare urlSourceId?: string;
  @tracked declare selectedHelpCenterIds?: string;
  @tracked declare lastUpdatedBy?: string;
  @tracked sortDirectionParam?: SortDirection;
  @tracked sortByParam?: string;
  @tracked selectedFolderFilterOption?: FolderFilterOption;
  @tracked selectedSyncIssueOption?: SyncIssuesFilterOptions;
  @tracked declare tagIds?: string;
  @tracked tagOperator: TagOperator = IN_OPERATOR;

  get convertedSelectedTypes(): number[] | undefined {
    return this.selectedTypes?.split(',').map((type) => Number(type));
  }

  get convertedSelectedHelpCenterIds(): string[] | undefined {
    return this.selectedHelpCenterIds?.split(',').map((id) => String(id));
  }

  get convertedTagIds(): string[] | undefined {
    return this.tagIds?.split(',').map((id) => String(id));
  }

  get convertedPredicates(): Array<Predicate> | undefined {
    return this.predicates && this.predicates.length > 0 ? JSON.parse(this.predicates) : undefined;
  }

  get convertedFiltersApplied(): string[] | undefined {
    return this.filtersApplied && this.filtersApplied.length > 0
      ? JSON.parse(this.filtersApplied)
      : undefined;
  }

  get sortBy(): string | undefined {
    return this.sortByParam || this.knowledgeHubService.api?.defaultSearchParams.sort_by;
  }

  get sortDirection(): SortDirection | undefined {
    return (
      this.sortDirectionParam || this.knowledgeHubService.api?.defaultSearchParams.sort_direction
    );
  }

  @action setActiveFilter(filter?: string) {
    this.activeFilter = filter;
  }

  @action setFiltersApplied(filters: string[]) {
    this.filtersApplied = filters && filters.length > 0 ? JSON.stringify(filters) : undefined;
  }

  @action
  setSearchTerm(searchTerm?: string): void {
    this.searchTerm = searchTerm;
  }

  @action
  setLocale(locale: string | null): void {
    this.locale = locale;
  }

  @action
  setPredicates(predicates: Array<Predicate>): void {
    this.predicates = predicates && predicates.length > 0 ? JSON.stringify(predicates) : undefined;
  }

  @action
  setStatus(status: string | null): void {
    this.status = status;
  }

  @action
  setChatbotState(chatbotState?: string): void {
    this.chatbotState = chatbotState;
  }

  @action
  setCopilotState(copilotState?: string): void {
    this.copilotState = copilotState;
  }

  @action
  setCreatedBy(createdBy?: string): void {
    this.createdBy = createdBy;
  }

  @action
  setSelectedTypes(selectedTypes?: Array<EntityType>): void {
    this.selectedTypes =
      selectedTypes && selectedTypes?.length > 0 ? selectedTypes?.join(',') : undefined;
  }

  @action
  setUrlSourceId(urlSourceId?: string): void {
    this.urlSourceId = urlSourceId;
  }

  @action
  setSelectedHelpCenterIds(selectedHelpCenterIds?: Array<string>): void {
    this.selectedHelpCenterIds =
      selectedHelpCenterIds && selectedHelpCenterIds?.length > 0
        ? selectedHelpCenterIds?.join(',')
        : undefined;
  }

  @action
  setTagIds(tagIds?: string[]): void {
    this.tagIds = tagIds && tagIds?.length > 0 ? tagIds?.join(',') : undefined;
  }

  @action
  setTagOperator(tagOperator: TagOperator): void {
    this.tagOperator = tagOperator;
  }

  @action
  setLastUpdatedBy(lastUpdatedBy?: string): void {
    this.lastUpdatedBy = lastUpdatedBy;
  }

  @action
  setSortBy(sortBy: string): void {
    this.sortByParam = sortBy;
  }

  @action
  setSortDirection(sortDirection: SortDirection): void {
    this.sortDirectionParam = sortDirection;
  }

  @action
  setFolderFilterOption(folderFilterOption?: FolderFilterOption): void {
    this.selectedFolderFilterOption = folderFilterOption;
  }

  @action
  setSyncIssueFilterOption(syncIssueOption?: SyncIssuesFilterOptions): void {
    this.selectedSyncIssueOption = syncIssueOption;
  }

  @action
  resetFilters(): void {
    this.activeFilter = undefined;
    this.filtersApplied = undefined;
    this.searchTerm = undefined;
    this.locale = null;
    this.predicates = undefined;
    this.status = null;
    this.copilotState = undefined;
    this.chatbotState = undefined;
    this.createdBy = undefined;
    this.selectedTypes = undefined;
    this.selectedHelpCenterIds = undefined;
    this.lastUpdatedBy = undefined;
    this.selectedFolderFilterOption = undefined;
    this.selectedSyncIssueOption = undefined;
    this.urlSourceId = undefined;
    this.tagIds = undefined;
    this.tagOperator = IN_OPERATOR;
  }
}
