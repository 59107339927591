/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { readOnly, alias } from '@ember/object/computed';
import Controller, { inject as controller } from '@ember/controller';
export default Controller.extend({
  appController: controller('apps/app'),
  app: readOnly('appController.model'),
  userSources: alias('model.userSources'),
});
