/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-side-effects */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { schedule } from '@ember/runloop';
import { computed, action } from '@ember/object';
import { alias, readOnly, gt } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Controller, { inject as controller } from '@ember/controller';
import { applyFunction } from '@intercom/pulse/lib/computed-properties';
import { truncatedNumberFormatter } from 'embercom/lib/number-formatter';

export default Controller.extend({
  intercomEventService: service(),
  intl: service(),
  identityVerificationRiskStateService: service(),
  appController: controller('apps/app'),
  app: alias('appController.model'),
  admin: alias('appController.admin'),
  queryParams: ['showing'],

  updatedConversationCount: alias('pendingConversations.length'),
  todayIsShowing: false,
  showing: undefined,
  openedCount: 0,
  closedCount: 0,
  snoozedCount: 0,

  conversationsTotalCount: computed('openedCount', 'closedCount', 'snoozedCount', 'showing', {
    get() {
      return this.get(`${this.showing}Count`);
    },

    set(_, value) {
      return this.set(`${this.showing}Count`, value);
    },
  }),

  messengerInstalled: readOnly('app.hasAnyInstalledAtDate'),
  hasConversations: gt('conversations.length', 0),

  stateDropdownTitle: computed('intl.locale', 'showing', function () {
    if (this.showing === 'opened') {
      return this.intl.t('apps.app.feed.index.open');
    } else if (this.showing === 'snoozed') {
      return this.intl.t('apps.app.feed.index.snoozed');
    }

    return this.intl.t('apps.app.feed.index.closed');
  }),

  formattedConversationsTotalCount: applyFunction(
    truncatedNumberFormatter,
    'conversationsTotalCount',
  ),

  showIdentityVerificationWarning: computed(
    'identityVerificationRiskStateService.shouldShowLowNotification',
    function () {
      let shouldShow = this.get('identityVerificationRiskStateService.shouldShowLowNotification');

      if (shouldShow) {
        this.send('trackIdentityVerificationNotificationEvent', {
          action: 'viewed',
          object: 'banner',
        });
      }
      return shouldShow;
    },
  ),

  hideRepeatedDays() {
    schedule('afterRender', this, () => {
      let previousDaysAgo = -1;
      let currentDaysAgo = 0;
      $('.js__feed__date-ago-header')
        .toArray()
        .forEach((item) => {
          currentDaysAgo = parseInt($(item).attr('data-days-ago'), 10);
          if (previousDaysAgo === currentDaysAgo) {
            $(item).hide();
          } else {
            $(item).show();
          }
          previousDaysAgo = currentDaysAgo;
        });
    });
  },

  contractConversations() {
    this.conversations.setEach('isExpanded', false);
  },

  changeStatus: action(function (status) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'inbox',
      context: `filtered_by_${status}`,
    });
    this.set('showing', status);
  }),

  actions: {
    prependUpdates() {
      this.send('prependUpdatedConversations');
    },

    redirectToIdentityVerification() {
      this.send('trackIdentityVerificationNotificationEvent', {
        action: 'clicked',
        object: 'banner',
      });
      this.transitionToRoute('apps.app.settings.identity-verification');
    },

    trackIdentityVerificationNotificationEvent(data) {
      this.intercomEventService.trackEvent(`identity-verification-notification-${data.action}`, {
        action: data.action,
        object: data.object,
        place: 'platform_conversations',
        owner: 'growth',
      });
    },
  },
});
