/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import { readOnly } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import AttributeSettingsController from 'embercom/controllers/apps/app/settings/base/attribute-settings-controller';
import { action } from '@ember/object';

export default AttributeSettingsController.extend({
  isSaving: false,
  appController: controller('apps/app'),
  app: readOnly('appController.model'),
  qualificationAttributes: readOnly('model'),
  setIsSaving: action(function (isSaving) {
    this.set('isSaving', isSaving);
  }),
});
