/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable @intercom/intercom/no-legacy-modal */
import Controller from '@ember/controller';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Controller.extend({
  queryParams: ['section'],
  modalService: service(),
  identity: readOnly('model.identity'),
  messengerSettings: readOnly('model.messengerSettings'),
  customBotsSettings: readOnly('model.customBotsSettings'),
  botInboxSettings: readOnly('model.botInboxSettings'),
  aiAgentQuickReplySettings: readOnly('model.aiAgentQuickReplySettings'),
  aiAgentSettings: readOnly('model.aiAgentSettings'),
  answerCounts: readOnly('model.answerCounts'),
  liveResolutionBotBehaviors: readOnly('model.liveResolutionBotBehaviors'),

  actions: {
    completeConversationImportFlow() {
      this.send('refreshRoute');
      this.modalService.closeModal();
    },
  },
});
