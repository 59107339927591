/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-classic-classes */
import { bool, equal, readOnly } from '@ember/object/computed';
import Controller from '@ember/controller';
import EventTracking from 'embercom/components/mixins/articles/event-tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import ArticleGroup from 'embercom/models/articles/article-group';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';

const COLLECTION_LIST_ROUTE_NAME = 'apps.app.articles.site.collections.index';

export default Controller.extend(EventTracking, {
  notificationsService: service(),
  helpCenterService: service(),
  realTimeEventService: service(),
  permissionsService: service(),
  appService: service(),
  intl: service(),
  intercomConfirmService: service(),
  router: service(),
  store: service(),

  app: readOnly('appService.app'),
  helpCenterSite: readOnly('helpCenterService.site'),
  fetchingSite: readOnly('helpCenterService.fetchingSite'),
  isLoading: bool('_saveCollection.isRunning'),
  currentImportId: readOnly('currentImport.id'),

  init() {
    this._super(...arguments);
    this.realTimeEventService.on('ImportStarted', this, '_handleStartedImport');
    this.realTimeEventService.on('ImportCompleted', this, '_handleCompletedImport');
    this.realTimeEventService.on('ArticleSyncError', this, '_handleArticleSyncError');
  },

  _handleStartedImport(event) {
    this.set('hasActiveApiImport', true);
    this.getSyncSettings.perform();
  },

  _handleCompletedImport(event) {
    this.set('hasActiveApiImport', false);
    this.set('currentImport', null);

    if (this.router.currentRouteName === COLLECTION_LIST_ROUTE_NAME) {
      this.getSyncSettings.perform();
      this.notificationsService.notifyConfirmation(
        this.intl.t('articles.collections.import.notification.success'),
      );
      this.send('refreshModel');
    }
  },

  _handleArticleSyncError(event) {
    if (this.router.currentRouteName === COLLECTION_LIST_ROUTE_NAME) {
      this.notificationsService.notifyError(this.intl.t('articles.settings.sync.error.info'));
    }
  },

  _saveCollection: task(function* (collection) {
    let metadata = {
      action: 'created',
      place: 'collection_list',
    };
    let type = 'collection';
    try {
      yield collection.saveIfDirty();
      this.trackEducateCollectionOrSectionEvent(collection, metadata);
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('articles.collections.import.notification.error', { errorType: type }),
      );
      collection.rollbackAttributes();
    }
  }),

  _transitionToCollection(collection) {
    this.transitionToRoute('apps.app.articles.site.collections.collection', collection.get('id'));
  },

  getApiImport() {
    return ajax({
      url: '/ember/article_import_jobs/current_import',
      type: 'GET',
      data: { app_id: this.app.id },
    });
  },

  syncArticles: action(function () {
    this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION).then(() => {
      this.set('showArticleSyncModal', true);
    });
  }),

  getSyncSettings: task(function* () {
    let syncSettings = yield this.store.findAll('articles/article-sync-setting');
    let zendeskSyncSetting = syncSettings.find((setting) => setting.provider === 'zendesk');
    this.set('zendeskSyncSetting', zendeskSyncSetting);
  }),

  triggerManualSync: task(function* () {
    yield this.zendeskSyncSetting?.triggerManualSync();
  }),

  pauseRegularSync: task(function* () {
    yield this.zendeskSyncSetting?.pause();
    yield this.getSyncSettings.perform();
    return;
  }),

  synchronizationEnabled: equal('zendeskSyncSetting.status', 'active'),

  removeRegularSync: task(function* () {
    try {
      yield this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    } catch (e) {
      return;
    }

    try {
      yield this.pauseRegularSync?.perform();
      this.set('showArticleCleanupBeforeSyncModal', false);
      this.set('showArticleImportModal', true);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.sync.remove-sync.notification.error'),
      );
      this.set('showArticleCleanupBeforeSyncModal', false);
    }
  }),

  removeSyncBeforeOtherSync: task(function* () {
    try {
      yield this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
    } catch (e) {
      return;
    }

    try {
      yield this.pauseRegularSync?.perform();
      this.set('showRemoveOtherSyncModal', false);
      this.set('showArticleSyncModal', true);
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('articles.settings.sync.remove-sync.notification.error'),
      );
      this.set('showRemoveOtherSyncModal', false);
    }
  }),

  importArticles: action(function () {
    this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION).then(() => {
      this.set('showArticleImportModal', true);
    });
  }),

  actions: {
    createCollection() {
      this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION).then(() => {
        let home = this.store.peekRecord('articles/article-group', 'home');
        let collection = ArticleGroup.createNewFolder(
          home,
          this.intl.t('articles.collections.new-collection-placeholder'),
        );
        return this._saveCollection.perform(collection).then(() => {
          this._transitionToCollection(collection);
        });
      });
    },
  },
});
