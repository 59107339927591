/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { get } from 'embercom/lib/ajax';

export default class WorkflowConnectorCustomAction extends Controller {
  @service store;
  @service appService;
  @tracked filters = {
    success: 'any',
    error_type: 'any',
    created_at: 168,
  };

  @task({ restartable: true })
  *loadNextPage() {
    let actionId = this.model.action.id;
    let actionLogs = this.model.actionLogs;
    let currentPage = actionLogs.meta ? actionLogs.meta.page : 0;
    let nextPage = actionLogs.meta ? actionLogs.meta.next_page : 1;
    if (nextPage > currentPage) {
      let actionLogParams = {
        page: nextPage,
        app_id: this.appService.app.id,
        action_id: actionId,
      };
      if (this.filters) {
        actionLogParams.success = this.filters.success;
        actionLogParams.error_type = this.filters.error_type;
        actionLogParams.created_at = this.filters.created_at;
      }

      let response = yield get(
        '/ember/workflow_connector/action_execution_results',
        actionLogParams,
      );

      this.store.pushPayload({
        'workflow-connector/action-execution-result': response.action_execution_results,
      });

      actionLogs.set('meta', response.meta);
      actionLogs.pushObjects(
        response.action_execution_results.map((aer) =>
          this.store.peekRecord('workflow-connector/action-execution-result', aer.id),
        ),
      );
    }
  }

  @action
  updateFilters(filters = {}) {
    this.model.actionLogs.set('meta', null);
    this.model.actionLogs.clear();
    this.filters = filters;
    this.loadNextPage.perform();
  }
}
