/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ConversationDataController extends Controller {
  @service appService;
  @service store;

  get conversationAttributeDescriptors() {
    return this.model.conversationAttributeDescriptors.filter(
      (descriptor) => descriptor.isTicketDescriptor === false,
    );
  }

  @action
  handleAttributeCreated() {
    this.send('refreshRoute');
  }
}
