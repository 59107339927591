/* RESPONSIBLE TEAM: team-channels */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-controllers */
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { task } from 'ember-concurrency-decorators';

export default class EmailNotificationsController extends Controller {
  @service appService;
  @service notificationsService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get messagingSettings() {
    return this.model;
  }

  @task({ drop: true })
  *save() {
    try {
      yield this.model.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('apps.app.settings.email-notifications.settings-updated'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.email-notifications.not-updated'),
      );
    }
  }
}
