/* RESPONSIBLE TEAM: team-help-desk-experience */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
/* eslint-disable ember/no-observers */
import { equal } from '@ember/object/computed';
import { observer } from '@ember/object';
import AccountControllerBase from 'embercom/controllers/apps/app/account/base/account-controller-base';

export default AccountControllerBase.extend({
  assignmentSetting: null,
  isAssignOnReply: equal('assignmentSetting', 'true'),

  onModelChange: observer({
    dependentKeys: ['model'],

    fn() {
      this.reset();
    },

    sync: false,
  }),

  reset() {
    this.setProperties({
      assignmentSetting: this.get('model.assign_conversations_on_reply').toString(),
    });
  },

  beforeSave() {
    this.model.setProperties({
      assign_conversations_on_reply: this.isAssignOnReply,
    });
  },

  hasUnsavedChanges() {
    return this.get('model.assign_conversations_on_reply').toString() !== this.assignmentSetting;
  },

  revertUnsavedChanges() {
    this.reset();
  },
});
