/* RESPONSIBLE TEAM: team-proactive-support */

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Controller from '@ember/controller';

export default class DomainsController extends Controller {
  @service store;
  @service notificationsService;
  @service intercomEventService;
  @service appService;
  @service intl;
  @service router;

  queryParams = ['domain'];

  @tracked showAddCustomSenderModal = false;
  @tracked openSectionId;

  @action
  setSectionId() {
    let domains = this.hydratedDomains.map((hydratedDomain) => hydratedDomain.dkimRecord.domain);
    this.openSectionId = domains.indexOf(this.router.currentRoute.queryParams.domain);
  }

  get app() {
    return this.appService.app;
  }

  get emailAddressHash() {
    let addressHash = new Map();
    this.app.customEmailAddresses.forEach((address) => {
      let domain = address.email.substring(address.email.lastIndexOf('@') + 1).toLowerCase();

      if (!addressHash.has(domain)) {
        addressHash.set(domain, []);
      }
      addressHash.get(domain).push({
        email: address.email,
        verified: address.verified,
        id: address.id,
      });
    });

    return addressHash;
  }

  get hydratedDomains() {
    let emailAddressHash = this.emailAddressHash;
    let domains = [];

    this.app.dkim_settings.forEach((record) => {
      let customBounceSetting = this.app.customBounceSettings.find((bounceSetting) => {
        return bounceSetting.rootDomain === record.domain;
      });

      let authenticated =
        record.validRecordExists &&
        customBounceSetting.validRecordExists &&
        record.validDmarcExists;

      domains.push({
        authenticated,
        dkimRecord: record,
        customBounceSetting,
        customAddresses: emailAddressHash.get(record.domain.toLowerCase()),
      });
    });
    return domains;
  }

  get allDomainsAuthenticated() {
    return this.hydratedDomains.every(
      (domain) =>
        domain.dkimRecord.validRecordExists && domain.customBounceSetting.validRecordExists,
    );
  }

  get allDomainsUnauthenticated() {
    return this.hydratedDomains.every(
      (domain) =>
        !domain.dkimRecord.validRecordExists || !domain.customBounceSetting.validRecordExists,
    );
  }

  @action
  onOpenSectionChange(newOpenSectionId) {
    this.openSectionId = newOpenSectionId;
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.toggleProperty('showAddCustomSenderModal');
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_settings_page',
      });
    }
  }
}
