/* RESPONSIBLE TEAM: team-ai-chatbot */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import CustomAnswerEditorConfig from 'embercom/objects/visual-builder/configuration/editor/custom-answer';

export default class IndexController extends Controller {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get editorConfiguration() {
    return new CustomAnswerEditorConfig({ targetChannels: [] });
  }
}
