/* RESPONSIBLE TEAM: team-tickets-1 */
/* eslint-disable ember/no-controllers */
import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TicketsReportController extends Controller {
  @service appService;

  @tracked hasDeprecatedMetrics = false;

  @action
  setHasDeprecatedMetrics(hasDeprecatedMetrics) {
    this.hasDeprecatedMetrics = hasDeprecatedMetrics;
  }

  // Show deprecated warning banner
  // 1. The app has the tickets reporting metrics updated banner feature enabled
  // 2. The report has at least one deprecated metric
  @action
  showDeprecatedMetricWarning() {
    return this.appService.app.hasTicketsReportingMetricsUpdatedBanner && this.hasDeprecatedMetrics;
  }

  // Show metrics updated banner
  // 1. The app has the tickets reporting metrics updated feature enabled
  // 2. There are no deprecated metrics
  @action
  showUpdatedMetricBanner() {
    return this.appService.app.hasTicketsReportingUpdatedMetrics && !this.hasDeprecatedMetrics;
  }
}
